import React, { ReactNode } from "react"
import { Line, Title } from "./style"

type TitleProps = {
    children: ReactNode
}

export function FullTitle(props: TitleProps) {
    return (
        <Title>
            {props.children}
            <Line />
        </Title>
    )
}