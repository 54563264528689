import styled from "styled-components";

export const OnlineUsersTooltip = styled.span`
    display: block;
    height: auto;
    height: fit-content;
    width: fit-content;
    position: relative;

    color: ${p => p.theme["gray-500"]};
    font-size: ${p => p.theme["text-sm"]};

    padding: 9.5px 14px;
    background: ${p => p.theme["white"]};
    box-shadow: -3px 0px 8px -3px rgba(0, 0, 0, 0.3);
    border-radius: 7px;

    align-self: flex-end;
    margin-bottom: 20px;

    strong {
        font-weight: bold;
    }

    &::after {
        content: "";
        width: 15px;
        height: 15px;
        background: ${p => p.theme["white"]};

        display: block;
        position: absolute;
        right: 2px;
        top: 50%;
        transform: translate(50%, -50%) rotate(45deg);

        border-radius: 4px;
    }
`

export const OnlineUsersContainer = styled.div`
    width: fit-content;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-self: flex-end;
    gap: 10px;

    img {
        align-self: flex-end;
        width: 60px;
        max-height: 81px;
        object-fit: none;
        object-position: top center;
    }
    padding-bottom: 2px;
`

export const NavigationContainer = styled.nav`
    display: flex;
    flex-direction: bottom;
    gap: 0;

    width: 100%;
    /* position: absolute;
    bottom: 1px; */
`

export const MenuLinks = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;

    @supports (not(gap: 12px)) {
        a + a {
            margin-left: 12px;
        }
    }

    height: 32px;

    a {
        display: flex;
        align-items: flex-start;

        font-size: ${p => p.theme["text-sm"]};
        font-weight: bold;
        color: ${p => p.theme["white"]};
        background: ${p => p.theme["yellow-500"]};

        padding: 7px 20px;
        border-radius: 7px 7px 0 0;

        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);

        max-height: 30px;

        &:hover {
            background: ${p => p.theme["yellow-300"]};
            transition: background .2s;
        }

        &.secondary {
            background: ${p => p.theme["green-400"]};
        }

        &.secondary:hover {
            background: ${p => p.theme["green-200"]};
        }

        &.active {
            /* fixing the sizing problem that border property causes */
            margin-top: -3px;
            margin-left: -2px;

            border-width: 2px 2px 0px 2px;
            border-style: solid;
            border-color: ${p => p.theme["black"]};
            padding-bottom: 9px;

            color: ${p => p.theme["gray-500"]};
            background: ${p => p.theme["white"]};
            box-shadow: 0px -5px 10px -1px rgba(0, 0, 0, 0.05);
            text-shadow: none;

            max-height: unset;
        }
    }
`

export const SubNavContainer = styled.div`
    width: 100%;
    max-width: 100%;
    height: 40px;
    padding: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;

    background: ${p => p.theme["white"]};
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 0px 0px 5px 5px;

    clip-path: inset(0px -100% -100% -100%);

    @supports (not(gap: 12px)) {
        a + a {
            margin-left: 5px;
        }
    }

    /* position: absolute;
    bottom: -40px; */

    a, button {
        border: none;

        font-size: ${p => p.theme["text-sm"]};
        color: ${p => p.theme["gray-500"]};
        font-weight: 400;

        padding: 7px 10px;
        background: ${p => p.theme["gray-100"]};
        box-shadow: inset 0px 0px 0px 1px ${p => p.theme["gray-200"]};
        border-radius: 4px;

        &:hover {
            background: ${p => p.theme["gray-200"]};
            box-shadow: inset 0 0 0 1px ${p => p.theme["gray-300"]};
            transition: background .2s, box-shadow .2s;
        }

        &:not(.active):active {
            filter: brightness(0.95);
        }

        &.active {
            font-weight: bold;
            background: ${p => p.theme["gray-300"]};
            box-shadow: inset 0 0 0 1px ${p => p.theme["gray-300"]};
            transition: background .2s;
        }

        &.destructive {
            color: ${p => p.theme["danger-500"]};
        }

        &.destructive:hover {
            background: ${p => p.theme["danger-200"]};
            box-shadow: inset 0px 0px 0px 1px ${p => p.theme["danger-500"]};
            transition: background .2s, box-shadow .2s;
        }
    }
`

export const MDNavigationMenu = styled.div`
    position: absolute;
    left: 0;
    bottom: 0%;
    transform: translateY(calc(50% + 4px));

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0px;

    width: 100%;
`

export const SMNavigationMenu = styled(SubNavContainer)`
    position: absolute;
    bottom: 0;
    transform: translateY(100%);

    button {
        display: flex;
        align-items: center;
        gap: 10px;

        font-weight: 500;
    }

    ${OnlineUsersTooltip} {
            position: relative;
            inset: 0;
            margin: 0;
            padding: 0;
            background: none;
            box-shadow: none;
            height: fit-content;
            width: fit-content;

            align-self: center;

            &::after {
                display: none;
            }

            @media (min-width: 748px) {
                display: none;
            }
    }
`

export const HeaderContents = styled.div`
    position: relative;

    width: 100%;
    max-width: 1000px;
    margin: 20px 20px 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .logo {
        display: block;
        height: 100%;
        align-self: flex-start;

        display: flex;
        align-items: flex-start;
    }

    @media (min-width: 1040px) {
        ${SMNavigationMenu} {display: none;}
    }

    @media (max-width: 1040px) {
        ${MDNavigationMenu} {display: none;}
    }

    @media (max-width: 748px) {
        ${OnlineUsersContainer} {display: none;}
        justify-content: center;
    }
`

export const HeaderContainer = styled.header`
    width: 100%;
    height: 160px;
    background: url("/header-bg.png") repeat-x left 0px, ${p => p.theme["blue-gradient"]};

    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: center;

    position: relative;
    margin-bottom: 40px;

    @supports (not(display: grid)) {
        margin-bottom: 90px;
    }

    .logo:focus {
        outline: none;

    }
`