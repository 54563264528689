import React from "react";
import { Navigate } from "react-router-dom";
import { Button } from "../../../../components/Button";
import { Input } from "../../../../styles/global";
import { ButtonsRow, Form, GoBackButton } from "../style";

type SecondStepProps = {
    nextStepFunction: (value: number) => void;
}

export function EmailSecondStep(props: SecondStepProps) {

    function handlePrevPage(e: React.FormEvent<HTMLButtonElement>) {
        e.preventDefault()

        props.nextStepFunction(0)
    } 

    return (
        <Form>
            <label htmlFor="verificationCode" title="Insira o código de verificação enviado no seu e-mail.">
                Código de confirmação
            </label>

            <Input
                id="verificationCode"
                name="verificationCode"
                placeholder="XXXX-XX-XXXX"
            />

            <ButtonsRow>
                <GoBackButton type={"button"} onClick={handlePrevPage}>
                    Voltar
                </GoBackButton>

                <Button type="submit" variant="success">
                    Confirmar
                </Button>
            </ButtonsRow>
        </Form>
    )
}