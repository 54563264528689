import React from 'react';
import { ProductCard } from "../../components/ProductCard";
import { ShoppingCart } from "../../components/ShoppingCart";
import { Box, BoxContent, BoxTitle } from "../../styles/global";
import { AsideContainer, FaqContainer, MainContainer, PurchaseNicknamePageLayout } from "./style";

export function PurchaseNickname() {
    return (
        <PurchaseNicknamePageLayout>
            <MainContainer>
                <ProductCard color="blue" price="R$ 30,00" name="Mudança de nickname">
                    <ul>
                        <li>Escolha qualquer nickname em desuso</li>
                    </ul>
                </ProductCard>

                <ProductCard color="orange" price="R$ 30,00" name="Mudança de nickname premium">
                    <ul>
                        <li>Escolha qualquer nickname em desuso</li>
                        <li>Escolha o nickname de uma conta que não loga há 4 anos ou mais</li>
                    </ul>
                </ProductCard>
            </MainContainer>

            <AsideContainer>
                <ShoppingCart />
                
                <Box>
                    <BoxTitle variant="colorful">
                        FAQs
                    </BoxTitle>
                    <BoxContent>
                        <FaqContainer>
                            <h4>O que faço após pagar?</h4>
                            <p>
                                Após pagar, <a href="">clique aqui</a> para preencher um formulário aonde você enviará o comprovante do seu pagamento + o nick requerido
                            </p>
                        </FaqContainer>
                        
                        <FaqContainer>
                            <h4>Qual prazo para receber?</h4>
                            <p>
                                Você terá seu nickname trocado em até 48h após preencher o formulário.
                            </p>
                        </FaqContainer>

                        <FaqContainer>
                            <h4>Como consigo suporte?</h4>
                            <p>
                                Se nenhuma das respostas acima sanaram sua dúvida, você pode entrar em contato conosco através do seguinte e-mail: <strong>contato@habblive.online</strong>.
                            </p>
                        </FaqContainer>

                        <FaqContainer>
                            <h4>Como posso receber rembolso?</h4>
                            <p>
                                A equipe Habblive não rembolsará nenhuma compra.
                            </p>
                        </FaqContainer>
                    </BoxContent>
                </Box>
            </AsideContainer>
        </PurchaseNicknamePageLayout>
    )
}