export const dateAndTimeFormatter = new Intl.DateTimeFormat("pt-BR", {
    dateStyle: "short",
    timeStyle: "short",
});

// são padrões da função Intl, nativa do JS.
// currency serve para por o R$ na frente, enquanto style serve para deixar no formato de dinheiro universal

// USAGE ----


// const date = "2022-10-25T00:25:28.370Z"
// const newDate = dateFormatter.format(new Date(date)