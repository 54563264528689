import styled from "styled-components";

export const TooltipContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const AdminExtendedView = styled.i`
    all: unset;
    line-height: 0;
    
    @media (min-width: 768px) {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    @media (max-width: 768px) {
        align-self: center;
    }

    color: ${p => p.theme["gray-600"]};
    padding: 6px;
    border-radius: 999px;
    background: ${p => p.theme["white"]};
`

export const UserAvatarAndNickname = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${p => p.theme["gray-700"]};
    font-size: ${p => p.theme["text-sm"]};
    font-weight: 700;

    img {
        width: 54px;
        height: 50px;
        object-fit: none;
        object-position: center;
    }
`

export const ListItem = styled.li`
    display: flex;
    flex-direction: row;
    gap: 12px;

    position: relative;
    padding: 8px 12px;
    border-radius: 6px;
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);

    &:nth-child(even) { background: ${p => p.theme["list-even-200"]}; }
    &:nth-child(odd) { background: ${p => p.theme["list-odd-200"]}; }

    p, span {
        flex: 1;
        margin: auto;
        font-weight: 700;
        font-size: ${p => p.theme["text-sm"]};
        color: ${p => p.theme["gray-600"]};

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    @media (max-width: 768px) {
        overflow: auto;
        overflow: overlay;

        &::-webkit-scrollbar {
            display: none;
        }

        & > *:not(i) {
            min-width: 150px;
        }
    }
`

export const ListContent = styled.ul`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const ListHeader = styled.div`
    width: 100%;
    padding: 0 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    h3 {
        font-size: ${p => p.theme["text-sm"]};
        font-weight: 700;
        color: ${p => p.theme["black"]};

        flex: 1;
    }

    @media (max-width: 768px) {
        display: none;
    }
`

export const ListWrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

export const Form = styled.form`
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin: 0px 10px 10px 10px;

    input {
        flex: 1;
    }

    button {
        max-height: 50px;
    }

    @media (max-width: 768px) {
        display: grid;
        grid-auto-rows: repeat(3, min-content);

        button {
            width: -webkit-fill-available;
        }
    }
`

export const MainContainer = styled.main`
    width: calc(100% - 40px);
    max-width: 1000px;

    display: flex;
    flex-direction: column;
    gap: 30px;
`