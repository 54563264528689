import React from 'react';
import { ReactNode } from "react";
import { Container } from "./style";


type CoinsProps = {
    image: "cambios" | "diamantes" | "gamepoints" | "promocoins" | "badges" | "respects"
    children: ReactNode
}

const imageTranslation = {
    "cambios": "/cambios.png",
    "diamantes": "/diamonds.png",
    "gamepoints": "/games.png",
    "promocoins": "/newspaper.png",
    "badges": "/badge.png",
    "respects": "/star.png",
}

export function CoinsField(props: CoinsProps) {

    return (
        <Container>
            <img src={imageTranslation[props.image]} alt="" />
            {props.children}
        </Container>
    )
}