import styled, { keyframes } from "styled-components";

const logoAnimation = keyframes`
    0% {
        transform: rotate(-2deg) scale(1);
    }
    100% {
        transform: rotate(2deg) scale(1.02);
    }
`

const spinnerAnimation = keyframes`
    0% {
        transform: rotateZ(0deg);
    }

    100% {
        transform: rotateZ(360deg);
    }
`

export const LogoImg = styled.img`
    animation: ${logoAnimation} infinite 0.8s cubic-bezier(0.79, -1.33, 0.3, 2.82) alternate;
`

export const SpinnerImg = styled.img`
    animation: ${spinnerAnimation} linear infinite 1s;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
`

export const LoadingPageLayout = styled.main`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;

    z-index: 1;
    background: radial-gradient(50% 50% at 50% 50%, #2F2E54 0%, #1F1E36 100%);
    display: grid;
    place-items: center;
`