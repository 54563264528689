import { ArticleSlider, ArticlesListItem, ArticlesListWrapper, AsideContainer, CampaignList, CampaignListItem, CampaignListItemContent, GoToArchieveButton, MainContainer, NavigationButtons, HomePageLayout, RoomListItem, RoomListWrapper, TwitterWrapper } from "./styles";
import { Box, BoxTitle } from "../../styles/global";
import { HomeView } from "./HomeView";
import { NavLink } from "react-router-dom";
import { FaAngleLeft, FaAngleRight, FaArrowRight } from "react-icons/fa";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useInterval } from "usehooks-ts";

import { ApisContext } from "../../Contexts/ApisContext";


export function Home() {
    const [ currentArticle, setCurrentArticle ] = useState(0)
    const [ timeIsRunning, setTimeIsRunning ] = useState(true)

    const {OnlineCampaigns, LastArticles, PopularRooms} = useContext(ApisContext)

// 🎞️ Slider de notícias ----------------------------------------

    const articleRef = useRef<HTMLElement>(null)

    useEffect(() => {
        articleRef.current!.style.backgroundImage =
        `linear-gradient(-180deg, rgba(0, 0, 0, 0.25) 14.58%, rgba(0, 0, 0, 0) 57.29%), url(${LastArticles[currentArticle].coverUrl})`
    }, [currentArticle, setCurrentArticle])

    useInterval(() => {
        if(currentArticle >= LastArticles.length -1) {
            setCurrentArticle(0)
        } else {
            setCurrentArticle(currentArticle + 1)
        }
    }, timeIsRunning ? 5000 : null)
    
    function prevArticle() {
        if(currentArticle <= 0) {
            setCurrentArticle(LastArticles.length -1)
        } else {
            setCurrentArticle(currentArticle - 1)
        }

        setTimeIsRunning(false)
        setTimeout(() => setTimeIsRunning(true), 1000)
    }
    
    function nextArticle() {
        if(currentArticle >= LastArticles.length -1) {
            setCurrentArticle(0)
        } else {
            setCurrentArticle(currentArticle + 1)
        }

        setTimeIsRunning(false)
        setTimeout(() => setTimeIsRunning(true), 1000)
    }

// -------------------------------------------------------------

    return (
        <HomePageLayout>
          
        <MainContainer>

        {/* Pagina de visão e resumo do usuário */}
        <HomeView />

        {/* Box de campanhas ativas */}
        <Box>
            <BoxTitle variant="colorful" color="orange">Campanhas ativas</BoxTitle>
            <CampaignList>
                {OnlineCampaigns.map(campaign => {
                    return (
                        <CampaignListItem key={campaign.articleID}>
                            <img src={campaign.coverUrl} alt="" />
                            <CampaignListItemContent>
                                <h3>{campaign.title}</h3>
                                <span>{campaign.description}</span>
                            </CampaignListItemContent>
                            <NavLink to={`/comunidade/noticia/${campaign.articleID}`}><FaArrowRight size={24} /></NavLink>
                        </CampaignListItem>
                    )
                })}
            </CampaignList>
        </Box>

        {/* Box de Twitter */}
        <Box>
            <BoxTitle variant="colorful" color="blue">Habblive no Twitter</BoxTitle>

            <TwitterWrapper>
                {/* <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName="habblivein"
                    options={{
                        width: "100%",
                        height: "500px",
                    }}
                    theme="light"
                    noHeader={false}
                    noBorders={false}
                    noFooter={false}
                /> */}
                <a className="twitter-timeline" data-width="580" data-height="500" href="https://twitter.com/habblivein?ref_src=twsrc%5Etfw">Tweets by habblivein</a> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
            </TwitterWrapper>
        </Box>

        </MainContainer>

        <AsideContainer>

            {/* Notícias */}
            <Box>
                <ArticleSlider ref={articleRef}>
                    <h3>{LastArticles[currentArticle].title}</h3>
                    <p>{LastArticles[currentArticle].description}</p>
                    <NavLink to={`/comunidade/noticia/${LastArticles[currentArticle].id}`}>
                        Ver esta notícia
                        <FaArrowRight size={16} />
                    </NavLink>

                    <NavigationButtons>
                        <button type="button" aria-label="notícia anterior" onClick={prevArticle}>
                            <FaAngleLeft size={48} />
                        </button>
                        <button type="button" aria-label="notícia posterior" onClick={nextArticle}>
                            <FaAngleRight size={48} />
                        </button>
                    </NavigationButtons>
                </ArticleSlider>

                <ArticlesListWrapper>
                    {LastArticles.map(article => {
                        return (
                            <ArticlesListItem key={article.id}>
                                <NavLink to={`/comunidade/noticia/${article.id}`}>{article.title}</NavLink>
                            </ArticlesListItem>
                        )
                    })}
                </ArticlesListWrapper>

                <GoToArchieveButton to={"/comunidade/arquivo"}>
                    veja mais notícias
                    <FaArrowRight size={16} />
                </GoToArchieveButton>
            </Box>

            {/* Quartos populares */}
            <Box>
                <BoxTitle variant="colorful" color="purple">
                    Quartos do momento
                </BoxTitle>

                <RoomListWrapper>
                    {PopularRooms.map(room => {
                        return (
                            <RoomListItem key={room.roomId}>
                                <img src={
                                    room.usersAmount >= 40
                                    ? "/popular-room.png"
                                    : "/medium-room.png"
                                } alt="" />
                                <div>
                                    <strong>{room.roomName}</strong>
                                    <span>{room.roomOwner}</span>
                                </div>
                            </RoomListItem>
                        )
                    })}
                </RoomListWrapper>
            </Box>

        </AsideContainer>

        </HomePageLayout>
    )
}
