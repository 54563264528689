import { useParams } from "react-router-dom"
import { Box, BoxTitle, Input } from "../../../styles/global"
import { MainContainer } from "../style"
import countriesDatas from "../countrydata.json"
import React, { useContext } from "react"
import { UserContext } from "../../../Contexts/UserContext"
import { AdminExtendedView, Form, ListContent, ListHeader, ListItem, ListWrapper, TooltipContentWrapper, UserAvatarAndNickname } from "./style"
import { SortBySelect } from "./SortBySelect"
import { Button } from "../../../components/Button"
import { FaInfoCircle } from "react-icons/fa"
import { Tooltip } from "../../../components/Tooltip"
import { dateAndTimeFormatter } from "../../../utils/dateAndTimeFormatter"

type UserProps = {
    nickname: string;
    motto: string;
    lastLogIn: string;
    ip: string;
    city: string;
    isUsingVpn: boolean;
}

export function CountryPage() {
    const { country: ParamCountry } = useParams()
    const {userInfos: {isAdmin}} = useContext(UserContext)

    // return only infos from current country from json
    const currentCountry = countriesDatas.reduce((acc, country) => {
        if(country.countryName.toLowerCase().replaceAll(" ", "") === ParamCountry) {
            acc.countryName = country.countryName;
            acc.flagUrl = country.flagUrl;
            acc.onlineUsersTotal = country.onlineUsersTotal
            acc.users = [...country.users]
        }

        return acc
    }, {
        countryName: "",
        flagUrl: "",
        onlineUsersTotal: 0,
        users: [{
            city: "",
            ip: "",
            isUsingVpn: false,
            motto: "",
            nickname: "",
            lastLogIn: "",
        }]
    })

    return (
        <MainContainer>
            <Box>
                <BoxTitle variant="colorful">
                    {currentCountry.countryName}
                </BoxTitle>

                <Form>
                    {isAdmin ? <SortBySelect /> : null}
                    <Input
                        placeholder="Buscar usuário"
                    />
                    <Button type="button" size="small" variant="success">Buscar</Button>
                </Form>
            </Box>

            <ListWrapper>
                <ListHeader>
                    <h3>Usuário</h3>
                    <h3>Missão</h3>
                    <h3>Último login</h3>
                </ListHeader>

                <Box>
                    <ListContent>
                        {currentCountry.users.map((user: UserProps) => {
                            const {city, ip, isUsingVpn, lastLogIn, motto, nickname} = user
                            const avatarImg = `https://habblive.in/imageeer.php?user=${nickname}&action=wav&size=b&head_direction=3&direction=3&gesture=sml&headonly=1`
                            return (
                                <ListItem key={user.nickname}>
                                    <UserAvatarAndNickname>
                                        <img src={avatarImg} alt="" />
                                        {nickname}
                                    </UserAvatarAndNickname>
                                    <p>{motto}</p>
                                    <span>{dateAndTimeFormatter.format(new Date(lastLogIn))}</span>

                                    {isAdmin ?
                                        <Tooltip content={
                                            <TooltipContentWrapper>
                                                <p><strong>Ip:</strong> {ip}</p>
                                                <p><strong>Cidade:</strong> {city}</p>
                                                <p><strong>VPN:</strong> {isUsingVpn === true ? "sim" : "não"}</p>
                                            </TooltipContentWrapper>}>
                                            <AdminExtendedView>
                                                <FaInfoCircle />
                                            </AdminExtendedView>
                                        </Tooltip>
                                    : null}

                                </ListItem>
                            )
                        })}
                    </ListContent>
                </Box>
            </ListWrapper>
        </MainContainer>
    )
}