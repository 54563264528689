import React from 'react';
import { NavLink } from "react-router-dom";
import { HeaderContainer, HeaderContents, MDNavigationMenu, MenuLinks, NavigationContainer, OnlineUsersContainer, OnlineUsersTooltip, SMNavigationMenu, SubNavContainer } from "./styles";
import { MobileNavDialog } from "./subcomponents/DialogElements";


export function HeaderUnlogged() {
    const wavingUser = `https://habblive.in/imageeer.php?user=&action=wav&size=b&head_direction=3&direction=3&gesture=sml`

    return (
        <HeaderContainer>
        <HeaderContents>

                <NavLink to="/" className="logo">
                    <img src="/logo.png" id="logo" alt="Habblive" />
                </NavLink>

                <MDNavigationMenu>
                    <NavigationContainer>
                        <MenuLinks>
                            <NavLink to="/" end>Registre-se</NavLink>
                        </MenuLinks>
                    </NavigationContainer>

                    <SubNavContainer>
                        <NavLink to="/">Home</NavLink>
                    </SubNavContainer>
                </MDNavigationMenu>


                {/* menu de navegação para mobile */}
                <SMNavigationMenu>
                    <MobileNavDialog isLogged={false} />
                    <OnlineUsersTooltip><strong>0000</strong> usuários!</OnlineUsersTooltip>
                </SMNavigationMenu>


                {/* contador de usuários online */}
                <OnlineUsersContainer>
                    <OnlineUsersTooltip><strong>0000</strong> usuários!</OnlineUsersTooltip>
                    <img src={wavingUser} alt="Habbo avatar wavinge" />
                </OnlineUsersContainer>


        </HeaderContents>
        </HeaderContainer>
    )
}