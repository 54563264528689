import React from 'react';
import { Button } from "../../../components/Button";
import { BoxTitle, Input } from "../../../styles/global";
import { ButtonsRow, Container, Form, PaddingWrapper } from "./styles";
import { Switch } from "../../../components/LabelSwitch";

export function GeneralConfigs() {
    return (
        <Container>
            <BoxTitle variant="colorful" color="blue">
                Configurações gerais
            </BoxTitle>

            <Form>
                
                <PaddingWrapper>
                    <label htmlFor="mission">Alterar missão</label>
                    <Input
                        name="mission"
                        id="mission"
                        placeholder="Nos conte algo maneiro!"
                    />
                </PaddingWrapper>

                <Switch id="pedidosDeAmizade">
                    Receber pedidos de amizade
                </Switch>

                <Switch id="onlineParaTodos">
                    Exibir que estou online para todos
                </Switch>

                <Switch id="altoFps" defaultActive={false}>
                    Animações do jogo em 60 FPS
                </Switch>

                <Switch id="permitirSeguir">
                    Permitir que me sigam
                </Switch>

                <Switch id="permitirMencoes">
                    Receber menções
                </Switch>

                <Switch id="permitirCopiar">
                    Permitir que usuários copiem meu visual
                </Switch>

                <Switch id="permitirNegociar">
                    Permitir que usuários negociem comigo
                </Switch>
            </Form>

            <ButtonsRow>
                <Button variant="primary" type="reset">Redefinir</Button>
                <Button variant="success" type="submit">Salvar</Button>
            </ButtonsRow>

        </Container>
    )
}