import React, { ReactNode } from "react";
import { FaPlus, FaTimes } from "react-icons/fa";
import { ModalCloseButton, ModalContainer, ModalContent, ModalDescription, ModalHeader, ModalOverlay, ModalPortal, ModalTitle, ModalTrigger, ModalWrapper } from "../../../styles/DialogStyle";
import { CloseButton } from "../../../styles/global";
import { FriendHead, ListItem, ListWrapper, OnlineFriendsTrigger } from "./style";

type OnlineFriendsDialogProps = {
    children: ReactNode;
    onlineUsers: string[];
}

export function OnlineFriendsDialog(props: OnlineFriendsDialogProps) {
    return (
        <ModalContainer>
            <OnlineFriendsTrigger><FaPlus size={16} />{" "}{props.children}</OnlineFriendsTrigger>
            <ModalPortal>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader variant="colorful" color="yellow">
                        <ModalTitle>Amigos online</ModalTitle>
                        <ModalDescription>Uma lista que exibe seus amigos que estão online no momento.</ModalDescription>
                        <ModalCloseButton asChild>
                            <CloseButton type="button" aria-label="Close"><FaTimes size={20} /></CloseButton>
                        </ModalCloseButton>
                    </ModalHeader>

                    <ModalWrapper>
                        <ListWrapper>
                                {props.onlineUsers.map(user => {
                                    return (
                                        <ListItem key={user}>
                                            <FriendHead src={`https://habblive.in/imageeer.php?user=${user}&action=wav&size=b&head_direction=3&direction=3&gesture=sml&headonly=1`} alt="" />
                                            {user}
                                        </ListItem>
                                    )
                                })}
                        </ListWrapper>
                    </ModalWrapper>
                </ModalContent>
            </ModalPortal>
        </ModalContainer>
    )
}