
import React from 'react';
import { Button } from "../../../components/Button";
import { BoxTitle, Input } from "../../../styles/global";
import { Container, Form } from "./style";

export function PasswordConfigs() {
    return (
        <Container>
            <BoxTitle variant="colorful" color="blue">
                Mudar a senha
            </BoxTitle>

            <Form>

                <label htmlFor="currentPassword">Senha atual</label>
                <Input
                    id="currentPassword"
                    name="currentPassword"
                    placeholder="***"
                />
                
                <label htmlFor="newPassword">Nova senha</label>
                <Input
                    id="newPassword"
                    name="newPassword"
                    placeholder="*****"
                />
                <label htmlFor="repeatNewPassword">Repita a nova senha</label>
                <Input
                    id="repeatNewPassword"
                    name="repeatNewPassword"
                    placeholder="*****"
                />

                <Button variant="success">Salvar</Button>
            </Form>
        </Container>
    )
}