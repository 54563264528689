import React from 'react';
import { BadgesRow, Box, BoxContent, BoxContentLink, BoxTitle } from "../../styles/global";
import { StaffCard } from "./Components/StaffCard";
import { AsideContainer, GeaPageLayout, MainContainer } from "./style";

export function GeaPage() {
    return (
        <GeaPageLayout>
            <MainContainer>

            <StaffCard role="Gestor" key={"Gestor"} />
            <StaffCard role="Mestres" key={"Mestres"} />
            <StaffCard role="Arquitetos" key={"Arquitetos"} />
            <StaffCard role="Programadores" key={"Programadores"} />
            <StaffCard role="Pixel Artistas" key={"PixelArtistas"} />

            </MainContainer>

            <AsideContainer>
                <Box>
                    <BoxTitle variant="colorful">
                        O que é a staff
                    </BoxTitle>
                    <BoxContent>
                        <span>
                            TEXTO AQUI
                        </span>
                        <BadgesRow>
                            <img src="/emblema-staff.gif" alt="" />
                        </BadgesRow>
                    </BoxContent>
                </Box>


                <Box>
                    <BoxTitle variant="colorful">
                        Trabalhe conosco
                    </BoxTitle>
                    <BoxContent>
                        <span>
                            TEXTO AQUI
                        </span>

                        <BoxContentLink to="/noticiaaqui">
                            Torne-se um GEA
                        </BoxContentLink>
                    </BoxContent>
                </Box>
            </AsideContainer>
        </GeaPageLayout>
    )
}