import React from 'react';
import { formatDistance } from "date-fns"
import { ptBR } from "date-fns/locale"
import { Box, BoxTitle, WarningBadge } from "../../../../styles/global"
import { CardInfos, StaffBox, StaffContainer, StatusBadge, UserBadges } from "./style"


const UserQuery = [
    {
        nickname: "Floricultor",
        joinedAt: "2022-11-24T00:14:36.675Z",
        isLogged: true,
        badges: [
            "/sample-badge.gif",
            "/sample-badge.gif",
        ]
    },
    {
        nickname: "Mayla",
        joinedAt: "2019-11-26T00:14:36.675Z",
        isLogged: true,
        badges: []
    },
    {
        nickname: "Krauser",
        joinedAt: "2021-08-17T00:14:36.675Z",
        isLogged: false,
        badges: [
            "/sample-badge.gif",
            "/sample-badge.gif",
        ]
    },
    {
        nickname: "-ThyBlack",
        joinedAt: "2022-04-05T00:14:36.675Z",
        isLogged: true,
        badges: [
            "/sample-badge.gif",
            "/sample-badge.gif",
        ]
    },
    {
        nickname: "Klara",
        joinedAt: "2022-05-09T00:14:36.675Z",
        isLogged: false,
        badges: [
            "/sample-badge.gif",
            "/sample-badge.gif",
        ]
    },
    {
        nickname: "Paulinho",
        joinedAt: "2021-08-02T00:14:36.675Z",
        isLogged: false,
        badges: [
            "/sample-badge.gif",
            "/sample-badge.gif",
        ]
    },
]

const Staff = {
    "Gestor": [
        "Floricultor",
    ],
    "Mestres": [
        "Mayla",
        "Krauser",
    ],
    "Arquitetos": [
        "-ThyBlack",
        "Klara",
    ],
    "Programadores": [
        "Paulinho",
    ],
    "Pixel Artistas": [],
}

type StaffCardProps = {
    role: keyof typeof Staff
}

export function StaffCard(props: StaffCardProps) {

    return (
        <Box>
            <BoxTitle variant="colorful">
                {props.role}
            </BoxTitle>
            
            <StaffContainer>
                {/* Warning if there isn't anyone at this role */}
                {Staff[props.role].length <= 0 && <WarningBadge>Ops, não há ninguém nesse cargo!</WarningBadge>}

                {/* Default process */}
                {Staff[props.role].length > 0 && Staff[props.role].map(each => {
                    
                    const thisStaff = UserQuery.find(param => param.nickname === each)
                    const avatar = `https://habblive.in/imageeer.php?user=${thisStaff?.nickname}&action=wav&size=b&head_direction=3&direction=2&gesture=sml`
                    return (
                        <StaffBox key={thisStaff?.nickname}>
                            <img src={avatar} alt="" />

                            <CardInfos>
                                <h4>{thisStaff?.nickname}</h4>
                                <span>
                                    {`
                                        Na equipe há 
                                        ${
                                            formatDistance(new Date(thisStaff!.joinedAt), new Date(), {
                                                addSuffix: false,
                                                locale: ptBR,
                                            })
                                        }
                                    `}
                                </span>
                                <UserBadges>
                                    {thisStaff?.badges.map(badge => {
                                        return <img key={badge} src={badge} alt="" />
                                    })}
                                </UserBadges>

                                <StatusBadge isOnline={thisStaff!.isLogged}>{thisStaff?.isLogged ? "Online" : "Offline"}</StatusBadge>
                            </CardInfos>
                        </StaffBox>
                    )
                })}
            </StaffContainer>
        </Box>
    )
}