import styled from "styled-components";

export const PurchaseBagFooter = styled.footer`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;

    strong {
        font-weight: bold;
        font-size: ${p => p.theme["text-lg"]};
    }

    @media (max-width: 320px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;

        margin-top: 2rem;
    }
`

export const BoxContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-top: 0;

    font-size: ${p => p.theme["text-sm"]};
    font-weight: 400;
    color: ${p => p.theme["black"]};

    span, p {
        display: block;
        margin-bottom: 12px;
    }

    li {
        list-style: none;
        position: relative;
        background: ${p => p.theme["gray-100"]};
        border-radius: 4px;
        padding: 8px;
        width: 100%;

        color: ${p => p.theme["black"]};
        font-size: ${p => p.theme["text-sm"]};
        font-weight: bold;

        &:hover {
            background: ${p => p.theme["gray-200"]};
            transition: background 150ms;
        }

        & + li {
            margin-top: 4px;
        }

        button {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);

            box-shadow: none;
            padding: 0;
            width: 10px;
            height: 10px;
            outline-offset: 4px;

            svg {
                color: ${p => p.theme["danger-500"]};
            }
        }
    }

    & *:last-child {
        margin-bottom: 0;
    }
`