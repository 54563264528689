import React from 'react';
import { ShoppingCart } from "../../components/ShoppingCart";
import { BadgesRow, Box, BoxContent, BoxTitle } from "../../styles/global";
import { ProductCard } from "../../components/ProductCard";
import { PurchaseVipPageLayout, AsideContainer, MainContainer, FaqContainer } from "./style";

export function PurchaseVip() {
    return (
        <PurchaseVipPageLayout>
            <MainContainer>
                <ProductCard
                    color="white"
                    name="Vip Teste"
                    price="R$ 10,00"
                >
                    <ul>
                        <li>8 dias de VIP</li>
                        <li>Emblema exclusivo de VIPS</li>
                        <li>Enable exclusivo de VIPS (801)</li>
                        <li>Sem mute por flood</li>
                        <li>Acesso aos raros vips nos domingos</li>
                        <li>Comando :viptag</li>
                        <li>Comando :typing</li>
                    </ul>
                </ProductCard>
                
                <ProductCard
                    color="blue"
                    name="Vip Alpha"
                    price="R$ 20,00"
                >
                    <ul>
                        <li>15 dias de VIP</li>
                        <li>Emblema exclusivo de VIPS</li>
                        <li>Enable exclusivo de VIPS (801)</li>
                        <li>Sem mute por flood</li>
                        <li>Acesso aos raros vips nos domingos</li>
                        <li>Comando :viptag</li>
                        <li>Comando :typing</li>
                    </ul>
                </ProductCard>

                <ProductCard
                    color="orange"
                    name="Vip Beta"
                    price="R$ 35,00"
                >
                    <ul>
                        <li>1 mês de VIP</li>
                        <li>Emblema exclusivo de VIPS</li>
                        <li>Enable exclusivo de VIPS (801)</li>
                        <li>Sem mute por flood</li>
                        <li>Acesso aos raros vips nos domingos</li>
                        <li>Comando :viptag</li>
                        <li>Comando :typing</li>
                        <li>35.000 diamantes na ativação</li>
                    </ul>
                </ProductCard>

                <ProductCard
                    color="green"
                    name="Vip Omega"
                    price="R$ 95,00"
                >
                    <ul>
                        <li>3 meses de VIP</li>
                        <li>Emblema exclusivo de VIPS</li>
                        <li>Enable exclusivo de VIPS (801)</li>
                        <li>Sem mute por flood</li>
                        <li>Acesso aos raros vips nos domingos</li>
                        <li>Comando :viptag</li>
                        <li>Comando :typing</li>
                        <li>125.000 diamantes na ativação</li>
                        <li>Pode trocar o nick por qualquer outro em desuso</li>
                    </ul>
                </ProductCard>
            </MainContainer>

            <AsideContainer>
                <ShoppingCart />

                <Box>
                    <BoxTitle variant="colorful">
                        Planos de VIP
                    </BoxTitle>

                    <BoxContent>
                        <p>
                            Vips são partes da elite do hotel. Você pode tornar-se um adquirindo qualquer um dos planos ao lado, além de receber todos os benefícios listados.
                        </p>

                        <BadgesRow>
                            <img src="/emblema-vip.gif" alt="" />
                        </BadgesRow>
                    </BoxContent>
                </Box>

                <Box>
                    <BoxTitle variant="colorful">
                        FAQs
                    </BoxTitle>

                    <BoxContent>
                        <FaqContainer>
                            <h4>Como receberei o meu VIP?</h4>

                            <p>
                                Normalmente, este será ativado na confirmação da compra. Se isso não acontecer, preencha este <a href="">formulário</a> e aguarde.
                            </p>
                        </FaqContainer>

                        <FaqContainer>
                            <h4>Qual prazo para receber?</h4>

                            <p>
                                Você terá seu VIP ativado em até 48h após a compra.
                            </p>
                        </FaqContainer>
                        
                        <FaqContainer>
                            <h4>Como consigo suporte?</h4>

                            <p>
                                Se nenhuma das respostas acima sanaram sua dúvida, você pode entrar em contato conosco através do seguinte e-mail: <strong>contato@habblive.online</strong>.
                            </p>
                        </FaqContainer>

                        <FaqContainer>
                            <h4>Como posso receber reembolso?</h4>

                            <p>
                                A equipe Habblive não reembolsará nenhuma compra.
                            </p>
                        </FaqContainer>
                    </BoxContent>
                </Box>
            </AsideContainer>
        </PurchaseVipPageLayout>
    )
}