import React, { useState } from "react"
import { BoxTitle } from "../../../styles/global"
import { EmailFirstStep } from "./EmailFirstStep"
import { EmailSecondStep } from "./EmailSecondStep"
import { Container } from "./style"

export function EmailConfigs() {
    const [ step, setStep ] = useState(0)

    function changeStep(value: number) {
        setStep(value)
    }

    return (
        <Container>
            <BoxTitle variant="colorful">
                Mudar o e-mail
            </BoxTitle>
            
            {step === 0 && <EmailFirstStep nextStepFunction={changeStep} />}
            {step === 1 && <EmailSecondStep nextStepFunction={changeStep} />}
        </Container>
    )
}