import React from 'react';
import { ReactNode } from "react";
import { Button } from "../Button";
import { ProductContainer, ProductHeader } from "./style";

type ProductCardProps = {
    color: "white" | "blue" | "orange" | "green";
    name: string;
    price: string;
    children: ReactNode;
}

export function ProductCard(props: ProductCardProps) {
    return (
        <ProductContainer>
            <ProductHeader color={props.color}>
                <h3>{props.name}</h3>
                <span>{props.price}</span>
            </ProductHeader>

            {props.children}

            <Button variant="success" hasIcon={true} icon="plus" size="small">
                Adicionar ao carrinho
            </Button>
        </ProductContainer>
    )
}