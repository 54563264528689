import React from 'react';
import { Column, LoadingPageLayout, LogoImg, SpinnerImg } from "./style";
export function Loading() {
    return (
        <LoadingPageLayout>
            <Column>
                <LogoImg src="/logo.png" alt="" draggable={false} />
                <SpinnerImg src="/spinner.png" alt="" draggable={false} />
            </Column>
        </LoadingPageLayout>
    )
}