import styled from "styled-components";

export const Container = styled.div`
    flex: 1;
    min-width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 6px;

    background: ${p => p.theme["gray-100"]};
    color: ${p => p.theme["gray-500"]};
    font-size: ${p => p.theme["text-sm"]};
    font-weight: bold;
    transition: background 100ms;
    border-radius: 6px;

    img {
        width: 30px;
        height: 30px;
        object-fit: none;
        object-position: center;
        background: ${p => p.theme["gray-400"]};
        border-radius: 6px;
        transition: background 50ms;
    }

    &:hover {
        background: ${p => p.theme["black"]};
        color: ${p => p.theme["white"]};

        img {
            background: ${p => p.theme["white"]};
        }
    }
`