import styled, { css } from "styled-components";

type StatusBadgeProps = {
    isOnline: boolean;
}

export const StatusBadge = styled.span<StatusBadgeProps>`
    display: block;
    margin-top: 8px;
    width: fit-content;
    border-radius: 999px;

    font-weight: 700;
    font-size: ${p => p.theme["text-sm"]};
    color: ${p => p.theme["white"]};
    padding: 4px 16px;

    user-select: none;

    position: absolute;
    right: 10px;
    top: 2px;

    /* make its color change according to the boolean value */
    ${p => p.isOnline ?
        css`background: ${p => p.theme["green-500"]};`
        :
        css`background ${p => p.theme["black"]};`
    }
`

export const UserBadges = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
    margin-top: 8px;

    img {
        padding: 8px;
        border-radius: 4px;
        background: ${p => p.theme["gray-200"]};
    }
`

export const CardInfos = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    h4 {
        font-size: ${p => p.theme["text-md"]};
        font-weight: bold;
        color: ${p => p.theme["black"]};
    }

    span:not(${StatusBadge}) {
        color: ${p => p.theme["gray-700"]};
        font-size: ${p => p.theme["text-sm"]};
        font-weight: 400;
    }
`

export const StaffBox = styled.div`
    display: flex;
    gap: 10px;

    background: ${p => p.theme["gray-100"]};
    padding: 12px;
    border-radius: 4px;

    position: relative;

    > img {
        width: min-content;
        height: 100px;
        object-fit: none;
        object-position: bottom;
        box-sizing: content-box;

        padding: 8px;
        outline: 1px solid ${p => p.theme['gray-400']};
        outline-offset: -1px;
        border-radius: 4px;
    }
`

export const StaffContainer = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;
`
