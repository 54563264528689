import React, { forwardRef } from "react";
import { FaTimes } from "react-icons/fa";
import { CloseButton } from "../../../../styles/global";
import { LightBoxContainer } from "./style";

type lightBoxProps = {
    imgUrl: string;
    closeModalFunction: () => void;
}

const LightBox: React.ForwardRefRenderFunction<HTMLDialogElement, lightBoxProps> = ({imgUrl, closeModalFunction}: lightBoxProps, ref) => {
    function handleCloseModal() {
        closeModalFunction()
    }

    return (
        <LightBoxContainer ref={ref}>
            <CloseButton
            type="button"
            aria-label="Close"
            onClick={handleCloseModal}
            >
                <FaTimes size={20} />
            </CloseButton>
            
            <img src={imgUrl} alt="" />
        </LightBoxContainer>
    )
}

export default forwardRef(LightBox)