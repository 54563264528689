import styled, { css } from "styled-components"
import { Box } from "../../../styles/global";


type UserInformationRowProps = {
    variant: "default" | "dark";
}


export const HideableUserInfos = styled.div`
    height: 0;
    transition: height 150ms ease;

    &[data-closed] #hideableArea button {
        display: none;
    }
`

export const RightMarginImg = styled.img`
    margin-right: 8px;
    align-self: center;
    justify-self: center;

    image-rendering: pixelated;
`

export const CoinsRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 12px;
    overflow-x: overlay;

    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
        height: 10px;
        display: none;
    }


    & > div {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 5px;

        scroll-snap-align: center;

        span {
            color: ${p => p.theme["gray-400"]};
            font-weight: bold;

            user-select: none;
        }

        &:hover > span {
            color: ${p => p.theme["white"]}
        }
    }

    .coin {
        width: 25px;
        height: 25px;
        border-radius: 4px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .diamond {

        background: ${p => p.theme["blue-500"]};
    }

    .gold {
        background: ${p => p.theme["yellow-500"]};
    }

    .ducket {
        background: ${p => p.theme["violet-500"]};
    }
`

export const ShowMoreButton = styled.button`
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: ${p => p.theme["gray-800"]};

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    box-shadow: none;
    position: absolute;
    right: 5px;

    &.opened {
        svg {
            transform: rotateZ(-180deg);
        }
    }

    svg {
        transform: rotatez(0deg);
        transition: all 150ms ease;
    }
`

export const UserInformationRow = styled.div<UserInformationRowProps>`
    display: grid;
    grid-template-columns: 1fr 195px;

    span, p, div, strong {
        color: ${p => p.theme["white"]};
        font-size: ${p => p.theme["text-sm"]};
        white-space: nowrap;
    }

    strong {
        margin-right: 5px;
    }

    span {
        strong {
            margin: 0;
        }
    }

    .ellipsis {
        /* display: -webkit-box; */
        /* -webkit-line-clamp: 1; */
        /* -webkit-box-orient: vertical; */
        /* overflow: hidden;
        text-overflow: ellipsis; */
    }

    & > div {
        height: 50px;
        border-left: 1px solid ${p => p.theme["gray-600"]};
        display: flex;
        align-items: center;
        position: relative;

        overflow-x: overlay;

        &::-webkit-scrollbar {
            height: 10px;
            display: none;
        }

        &:first-child {
            padding: 0 10px;
        }

        &:last-child {
            padding: 5px 8px;
        }
    }

    ${props => props.variant === "default" && css`
        & > div:first-child {
            background: ${p => p.theme["gray-600"]};
        }
        & > div:last-child {
            background: ${p => p.theme["gray-700"]};
        }
    `}

    ${props => props.variant === "dark" && css`
        & > div {
            background: ${p => p.theme["gray-800"]};
        }
    `}
`

export const UserInformationsColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
`

export const PlayRow = styled.div`
    display: flex;

    a {
        font-size: ${p => p.theme["text-lg"]};
        font-weight: 900;
        text-transform: uppercase;
        color: ${p => p.theme["white"]};

        display: flex;
        justify-content: center;
        align-items: center;

        flex: 1;
        height: 50px;

        &:focus {
            filter: brightness(1.05);
            outline: 1px solid #ffffff50;
            outline-offset: -1px;
        }

        &:first-child {
            background: ${p => p.theme["green-500"]};
        }

        &:last-child {
            background: ${p => p.theme["green-700"]}
        }
    }
`

export const HotelImage = styled.div`
    background: url("/hotel-vision-home.png");
    width: 100%;
    height: 199px;

    position: relative;

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 41px;
        background: rgba(0, 0, 0, 0.3);

        color: ${p => p.theme["white"]};
        font-size: ${p => p.theme["text-md"]};
        font-weight: 700;

        user-select: none;
    }

    img {
        position: absolute;
        left: 120px;
        bottom: 12px;
    }
`

export const HomePageView = styled(Box)`
    display: flex;
    flex-direction: column;
    position: relative;
`