import { Trigger } from "@radix-ui/react-dialog"
import styled from "styled-components"

export const FriendHead = styled.img`
    width: 54px;
    height: 50px;
    object-fit: none;
    object-position: center;
`

export const ListItem = styled.li`
    display: flex;
    flex-direction: row;
    gap: 4px;
    
    align-items: center;
    padding: 8px 12px;

    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    width: 100%;

    background: ${p => p.theme["list-odd-200"]};

    &:nth-child(even) {
        background: ${p => p.theme["list-even-200"]};
    }

    color: ${p => p.theme["gray-500"]};
    font-size: ${p => p.theme["text-sm"]};
    font-weight: 700;

    &:not(:last-child) {
        margin-bottom: 2px;
    }

    &:hover {
        filter: brightness(0.97);
    }
`

export const ListWrapper = styled.ul`
`

export const OnlineFriendsTrigger = styled(Trigger)`
    background: ${p => p.theme["gray-1000"]};
    width: 100%;
    height: 40px;

    font-size: ${p => p.theme["text-sm"]};
`