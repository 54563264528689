import styled from "styled-components"

export const AsideContainer = styled.aside`
    display: flex;
    flex-direction: column;
    gap: 30px;
`

export const MainContainer = styled.main`
    display: flex;
    flex-direction: column;
    gap: 30px;
`

export const OficialsPageLayout = styled.div`
    width: calc(100% - 40px);
    max-width: 1000px;
    margin: auto;

    display: grid;
    grid-template-columns: 600px 1fr;
    gap: 30px;

    @media (max-width: 1040px) {
        grid-template-columns: repeat(2, 1fr);

        ${MainContainer} {
            grid-template-columns: 1fr;
        }
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;

        ${AsideContainer} {
            grid-row: 1;

            a {
                padding: 12px;
                font-size: ${p => p.theme["text-md"]};
            }
        }
    }
`