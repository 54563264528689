import React from 'react';
import { NavLink } from "react-router-dom";
import { FooterContainer, FooterContent, FooterFooter, FooterHeader, FooterMenu, FooterSocialMedias, Line, SocialMediaRow } from "./style";
import { FaTwitter, FaFacebookF, FaDiscord } from "react-icons/fa"
import { GoToTopButton } from "./GoToTopButton/GoToTopButton";

export default function Footer() {
    return (
        <FooterContainer>
            <FooterContent>
                <FooterHeader>
                    <FooterMenu>
                        <img src="/officialBadge.png" alt="" />

                        <nav>
                            <h2>Menu rápido</h2>

                            <ul>
                                <li><NavLink to="/">Trabalhe Conosco</NavLink></li>
                                <li><NavLink to="/">Últimas notícias</NavLink></li>
                                <li><NavLink to="/">Termos e Condições</NavLink></li>
                                <li><NavLink to="/">Política de Privacidade</NavLink></li>
                            </ul>
                        </nav>
                    </FooterMenu>

                    <FooterSocialMedias>
                        <h2>Redes sociais</h2>

                        <SocialMediaRow>
                            <a href="" className="twitter"><FaTwitter size={24} /></a>
                            <a href="" className="facebook"><FaFacebookF size={24} /></a>
                            <a href="" className="discord"><FaDiscord size={24} /></a>
                        </SocialMediaRow>
                    </FooterSocialMedias>
                </FooterHeader>

                    <Line />

                <FooterFooter>
                    <div>
                    <strong>Habblive © 2015 - 2022</strong>
                        <span>Powered by <strong>Atlanta Servers BR</strong>. Design by <strong>Floricultor</strong>.</span>
                    </div>

                    <SocialMediaRow>
                            <a href="" className="twitter"><FaTwitter size={24} /></a>
                            <a href="" className="facebook"><FaFacebookF size={24} /></a>
                            <a href="" className="discord"><FaDiscord size={24} /></a>
                    </SocialMediaRow>
                </FooterFooter>

                <GoToTopButton />
            </FooterContent>
        </FooterContainer>
    )
}