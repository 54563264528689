import styled, { keyframes } from "styled-components"
import * as Accordion from "@radix-ui/react-accordion"

const open = keyframes`
    from {
        height: 0;
    }

    to {
        height: var(--radix-accordion-content-height);
    }
`

const close = keyframes`
    from {
        height: var(--radix-accordion-content-height);
    }

    to {
        height: 0;
    }
`

export const AccordionContent = styled(Accordion.Content)`
    margin-top: 4px;
    
    display: flex;
    flex-direction: column;
    gap: 4px;

    overflow: hidden;
    animation-fill-mode: both;

    a {
        display: block;
        background: ${p => p.theme["gray-200"]};
        box-shadow: inset 0px 0px 0px 1px ${p => p.theme["gray-400"]};
        border-radius: 4px;

        cursor: pointer;
        color: ${p => p.theme["gray-600"]};
        font-size: ${p => p.theme["text-md"]};
        font-weight: 400;

        padding: 8px 16px;
        width: 100%;
        transition: background .1s, box-shadow .0s;

        &:hover {
            background: ${p => p.theme["gray-300"]};
            box-shadow: inset 0px 0px 0px 1px ${p => p.theme["blue-500"]};
        }

        &:active {
            background: ${p => p.theme["gray-400"]};
        }

        &:focus {
            outline-offset: -2px;
            outline-color: ${p => p.theme["blue-500"]};
        }
    }

    &[data-state="open"] {
        animation: ${open} .3s ease-out;
        height: var(--radix-accordion-content-height);
    }

    &[data-state="closed"] {
        animation: ${close} .3s ease-out;
        transition: height 0s .3s;
        height: 0;
    }
`

export const AccordionTrigger = styled(Accordion.Trigger)`
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;

    padding: 14px 16px;
    border-radius: 4px;
    background: ${p => p.theme["gray-100"]};
    border: 1px solid ${p => p.theme["gray-300"]};
    box-shadow: 0px 1px 0px #00000010;

    color: ${p => p.theme["gray-600"]};
    font-size: ${p => p.theme["text-md"]};
    font-weight: 400;

    transition: background .1s, border .1s, font-weight .1s;

    &:hover {
        filter: brightness(0.95);
    }

    &:focus {
        outline-offset: -2px;
    }

    &[data-state="open"] {
        color: ${p => p.theme["white"]};
        background: ${p => p.theme["blue-500"]};
        border-color: ${p => p.theme["blue-700"]};
        font-weight: bold;
    }
    
    svg {
        transition: transform .3s ease-out;
        color: inherit;
    }

    &[data-state="open"] svg {
        transform: rotate(-180deg);
    }
`

export const AccordionHeader = styled(Accordion.Header)`
`

export const AccordionItem = styled(Accordion.Item)``

export const AccordionContainer = styled(Accordion.Root)`
    display: flex;
    flex-direction: column;
    gap: 16px;

    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
`