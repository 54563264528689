import { NavLink } from "react-router-dom";
import styled from "styled-components";

// Quartos do momento

export const RoomListItem = styled.li`
    display: flex;
    flex-direction: row;
    gap: 12px;

    padding: 9px 10px;

    &:not(:last-child) {
        border-bottom: 1px solid ${p => p.theme["gray-300"]};
    }

    &:nth-child(even) { background: ${p => p.theme["list-even-100"]} }
    &:nth-child(odd) { background: ${p => p.theme["list-odd-100"]} }

    &:hover {
        filter: brightness(0.98);
    }

    /* sub elements */

    img {
        align-self: flex-start;
    }

    div {
        display: flex;
        flex-direction: column;
    }

    strong {
        font-size: ${p => p.theme["text-sm"]};
        font-weight: bold;
        color: ${p => p.theme["gray-600"]};
    }

    span {
        font-size: ${p => p.theme["text-xs"]};
        color: ${p => p.theme["gray-500"]};
    }
`

export const RoomListWrapper = styled.ul`
    display: flex;
    flex-direction: column;
`


// Notícias

export const GoToArchieveButton = styled(NavLink)`
    width: 100%;
    padding: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    color: ${p => p.theme["white"]};
    font-weight: 700;
    font-size: ${p => p.theme["text-md"]};
    text-transform: uppercase;
    line-height: 0;

    background: ${p => p.theme["pink-500"]};
    border-radius: 0 0 6px 6px;

    &:hover {
        filter: brightness(0.95);
    }

    &:active {
        filter: brightness(0.9);
    }

    &:focus {
        outline-offset: -2px;
    }
`

export const ArticlesListItem = styled.li`
    &:not(:last-child) {
        border-bottom: 1px solid ${p => p.theme["gray-300"]};
    }
    &:nth-child(odd) { background: ${p => p.theme["list-odd-100"]}; }
    &:nth-child(even) { background: ${p => p.theme["list-even-100"]}; }

    &:hover {
        filter: brightness(0.98);
        transition: filter 100ms;
    }

    a {
        display: block;
        padding: 8px 12px;
        font-size: ${p => p.theme["text-sm"]};
        font-weight: 400;
        color: ${p => p.theme["gray-600"]};

        &:hover {
            color: ${p => p.theme["blue-700"]};
            transition: color 100ms;
        }

        &:focus {
            outline: 2px solid ${p => p.theme["blue-500"]};
            outline-offset: -2px;
            border-radius: 4px;
        }
    }
`

export const ArticlesListWrapper = styled.ul`
    display: flex;
    flex-direction: column;
`

export const NavigationButtons = styled.div`
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);

    button {
        all: unset;
        color: ${p => p.theme["white"]};
        filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.3));
        opacity: 0.8;
        transition: opacity 150ms;
        border-radius: 8px;
        
        display: flex;
        align-items: center;
        justify-content: center;

        @supports (not(all: unset)) {
            background: none;
            border: 0;
            box-shadow: none;
            border-radius: 999px;
            outline-color: white;
        }

        &:hover {
            opacity: 1;
        }

        &:focus {
            outline: 2px solid #ffffff40;
            border-radius: 999px;
            background: #00000025;
            opacity: 1;
        }

        &:active {
            background: #00000040;
        }
    }
`

export const ArticleSlider = styled.article`
    background-color: ${p => p.theme["gray-300"]};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    image-rendering: pixelated;

    width: 100%;
    height: 240px;
    position: relative;
    padding: 16px;

    display: flex;
    flex-direction: column;
    gap: 8px;
    color: ${p => p.theme["white"]};


    h3 {
        font-size: ${p => p.theme["text-lg"]};
        font-weight: 700;
        margin: 0;
    }

    p {
        margin: 0;
        font-weight: 400;
        font-size: ${p => p.theme["text-sm"]};

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    a {
        margin-top: 8px;

        color: inherit;
        font-size: ${p => p.theme["text-md"]};
        font-weight: 500;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        width: fit-content;
        cursor: pointer;
        position: relative;
        user-select: none;

        svg {
            position: relative;
            right: 0;
            transition: right 200ms;
        }
        
        &:hover svg {
            right: -3px;
        }

        &:focus {
            border-radius: 1px;
            outline: solid 2px ${p => p.theme["blue-500"]};
            outline-offset: 6px;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            
            width: 100%;
            height: 1px;
            bottom: -2px;

            background: ${p => p.theme["white"]};
            border-radius: 999px;
        }
    }
`

export const AsideContainer = styled.aside`
    display: flex;
    flex-direction: column;
    gap: 30px;

    @supports (not(gap: 30px;)) {
        & > *:not(:last-child) {
            margin-bottom: 30px;
        }
    }
`


// Twitter

export const TwitterWrapper = styled.div`
    margin: 0 10px 10px 10px;

    #twitter-widget-1, #twitter-widget-0, [title="Twitter Timeline"], iframe {
        max-width: 100%;
        min-width: unset;
    }
`

// CAMPANHAS ATIVAS

export const CampaignListItemContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
    
    h3 {
        color: ${p => p.theme["gray-600"]};
        font-size: ${p => p.theme["text-md"]};
    }

    span {
        color: ${p => p.theme["gray-500"]};
        font-size: ${p => p.theme["text-sm"]};

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

export const CampaignListItem = styled.li`
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 8px 10px;

    &:nth-child(even) { background: ${p => p.theme["list-even-200"]} }
    &:nth-child(odd) { background: ${p => p.theme["list-odd-200"]} }

    &:not(:last-child) {
        border-bottom: 1px solid ${p => p.theme["gray-300"]};
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 60px;
        height: 60px;
        padding: 0;

        cursor: pointer;
        border-radius: 4px;

        background: ${p => p.theme["orange-500"]};
        color: ${p => p.theme["white"]};

        &:hover {
            filter: brightness(0.95);
        }

        &:active {
            filter: brightness(0.9);
        }
    }

    img {
        flex-shrink: 0;
        align-self: flex-start;
        -webkit-user-drag: none;

        @media (max-width: 470px) {
            display: none;
        }
    }
`

export const CampaignList = styled.ul`
    display: flex;
    flex-direction: column;
`

// GERAL

export const MainContainer = styled.main`
    display: flex;
    flex-direction: column;
    gap: 30px;

    @supports (not(gap: 30px;)) {
        & > *:not(:last-child) {
            margin-bottom: 30px;
        }
    }
`

export const HomePageLayout = styled.div`
    width: calc(100% - 40px);
    max-width: 1000px;
    margin: auto;

    display: grid;
    grid-template-columns: 600px 370px;
    gap: 30px;

    @media (max-width: 1040px) {
        grid-template-columns: 60% 1fr;
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    @supports (not(display: grid)) {
        display: flex;
        ${MainContainer} {
            margin-right: 30px;
            width: 100%;
            max-width: 600px;
        }

        ${AsideContainer} {
            width: 100%;
            max-width: 370px;
        }
    }

    @supports (not(all: unset)) {
        button {
            border: 0;
            outline-color: white;
        }
    }
`