import React, { useContext } from "react";
import { Button } from "../../components/Button";
import { FullTitle } from "../../components/FullTitle";
import { Tooltip } from "../../components/Tooltip";
import { UserContext } from "../../Contexts/UserContext";
import { Box, BoxTitle, Input } from "../../styles/global";
import { dateAndTimeFormatter } from "../../utils/dateAndTimeFormatter";
import { dateFormatter } from "../../utils/dateFormatter";
import { UserInformationsColumn } from "../Home/HomeView/style";
import { CoinsField } from "./CoinsField";
import { AsideContent, BadgeAndFriendsContainer, BadgesBox, BadgeToolTipContent, FriendsBox, ProfileLayout, LongInformationField, MainContent, RoomCard, RoomsContainer, SearchUserBox, SearchUserForm, ShortInformationsContainer, SimpleButton, StoriesCard, StoriesContainer, StoriesInfos, UserProfileRow, Wrapper } from "./style";

export function UserProfile() {
    const {userInfos: {nickname, mission, lastLoggin, createdAt, coins, pictures, rooms, badges, allFriends}} = useContext(UserContext)

    const userAvatarImg = `https://habblive.in/imageeer.php?user=${nickname}&action=wav&size=l&head_direction=3&direction=2&gesture=sml`

    return (
        <Wrapper>
            <ProfileLayout>
                <MainContent>
                    <Box>
                        <BoxTitle variant="colorful" color="orange">
                            {nickname}
                        </BoxTitle>

                        <UserProfileRow>
                            <img src={userAvatarImg} alt="" />

                            <UserInformationsColumn>

                                <LongInformationField>
                                    <strong>Missão:</strong>
                                    {mission}
                                </LongInformationField>
                                <LongInformationField>
                                    <strong>Último login:</strong>
                                    {dateAndTimeFormatter.format(new Date(lastLoggin))}
                                </LongInformationField>
                                <LongInformationField>
                                    <strong>Criado em:</strong>
                                    {dateAndTimeFormatter.format(new Date(createdAt))}
                                </LongInformationField>
                                
                                <ShortInformationsContainer>
                                    {coins.map(coin => {
                                        return (
                                            <CoinsField key={coin.coin} image={coin.coin}>{coin.amount}</CoinsField>
                                        )
                                    })}
                                </ShortInformationsContainer>
                            </UserInformationsColumn>
                        </UserProfileRow>
                    </Box>

                    <FullTitle>Live Stories</FullTitle>

                    <StoriesContainer>
                        {pictures.map(picture => {
                            const customBackgroundStyle={
                                backgroundImage: `url(${picture.image})`
                            }

                            return (
                                <StoriesCard key={picture.date} style={customBackgroundStyle}>
                                    <StoriesInfos>
                                        {dateFormatter.format(new Date(picture.date))}

                                        <Button variant="success" hasIcon={true} icon="thumbs-up">{picture.likes}</Button>
                                    </StoriesInfos>
                                </StoriesCard>
                            )
                        })}
                    </StoriesContainer>

                    <FullTitle>Quartos</FullTitle>

                    <RoomsContainer>
                        {rooms.map(room => {
                            return (
                                <Tooltip key={room.id} content="Clique para copiar o ID do quarto">
                                    <RoomCard key={room.id} onClick={() => HandleCopyRoomToClipBoard(room.id)}>
                                        <img src={room.cover} alt="" />
                                        <span>{room.name}</span>
                                    </RoomCard>
                                </Tooltip>
                            )
                        })}
                    </RoomsContainer>


                </MainContent>

                <AsideContent>
                    
                    <SearchUserBox>
                        <BoxTitle variant="colorful">
                            Procurar usuário
                        </BoxTitle>

                        <SearchUserForm>
                            <label>
                                <Input
                                    required
                                    placeholder="Digite um nickname legal!"
                                    id="username"
                                    name="username"
                                />
                            </label>

                            <div>
                                <SimpleButton type="button">Ver usuário aleatório</SimpleButton>
                                <Button type="submit">Buscar</Button>
                            </div>
                        </SearchUserForm>
                    </SearchUserBox>

                    <Box>
                        <BoxTitle variant="colorful">
                            Emblemas
                        </BoxTitle>

                        <BadgeAndFriendsContainer>
                            {badges.map(badge => {
                                return (
                                    <Tooltip key={badge.code} content={
                                        <BadgeToolTipContent>
                                            <span>{badge.title}</span>
                                            <span>{badge.description}</span>
                                            <span className="code">{badge.code}</span>
                                        </BadgeToolTipContent>
                                    }>
                                        <BadgesBox>
                                            <img src={badge.image} alt="" />
                                        </BadgesBox>
                                    </Tooltip>
                                )
                            })}
                        </BadgeAndFriendsContainer>
                    </Box>

                    <Box>
                        <BoxTitle variant="colorful">
                            Amigos
                        </BoxTitle>

                        <BadgeAndFriendsContainer>
                            {allFriends.map(friend => {
                                return (
                                    <Tooltip key={friend} content={friend}>
                                        <FriendsBox>
                                            <img src={`https://habblive.in/imageeer.php?user=${friend}&action=wav&size=b&head_direction=3&direction=3&gesture=sml&headonly=1`} alt="" />
                                        </FriendsBox>
                                    </Tooltip>
                                )
                            })}
                        </BadgeAndFriendsContainer>
                    </Box>

                </AsideContent>
            </ProfileLayout>
        </Wrapper>
    )
}

function HandleCopyRoomToClipBoard(id: number) {
    navigator.clipboard.writeText(String(id))
}