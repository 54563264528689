import React, { useContext } from "react"
import { Article } from "../../components/Articles"
import { Button } from "../../components/Button"
import { ApisContext } from "../../Contexts/ApisContext"
import { Box, BoxTitle, Input } from "../../styles/global"
import { FilterSelect } from "./FilterSelect"
import { ArticlesGrid, BoxContent, Main, MobileWrapContainer } from "./style"

export function Archive() {
    const { Articles } = useContext(ApisContext)
    const ReversedArticlesArray = [...Articles].reverse()
    
    return (
        <Main>
            <Box>
                <BoxTitle variant="colorful">Arquivo de notícias</BoxTitle>

                <BoxContent>
                    <MobileWrapContainer>
                        <FilterSelect />

                        <Input
                            placeholder="Digite qualquer coisa"
                        />
                    </MobileWrapContainer>

                    <Button size="small" variant="success">
                        Buscar
                    </Button>
                </BoxContent>
            </Box>

            <ArticlesGrid>
                {ReversedArticlesArray.map(article => {
                    return (
                        <Article
                            key={article.id}
                            id={article.id}
                            title={article.title}
                            description={article.description}
                            topstoryUrl={article.coverUrl}
                        />
                    )
                })}
            </ArticlesGrid>
        </Main>
    )
}