import styled from "styled-components"
import { RadioGroup, RadioItem } from "../GenderRadioGroup/style"

export const ImgBackground = styled.img`
position: absolute;
right: 0;
bottom: 0;

opacity: 0.5;
z-index: -1;
pointer-events: none;
image-rendering: pixelated;

@media (max-width: 800px) {
    display: none;
}
`

export const Form = styled.form`
display: flex;
flex-direction: column;

width: 100%;
max-width: 450px;

label + input {
    margin-top: 6px;
}

input + label {
    margin-top: 16px;
}

label {
    user-select: none;
    display: block;
}

button {
    margin-top: 16px;
}

@media (max-width: 800px) {
    max-width: unset;
}

@media (max-height: 720px) {
    overflow-y: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}
`

export const FormRow = styled.div`
margin-top: 8px;

display: flex;
flex-direction: row;
align-items: center;

gap: 6px;
justify-content: space-between;

.fakeCaptcha {
    height: 68px;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

button {
    margin-top: 0;
}

@media (max-width: 800px) {
    flex-direction: column;
    width: 100%;

    .fakeCaptcha {
        width: 100%;
    }

    ${RadioGroup} {
        width: 100%;

        ${RadioItem} {
            flex: 1;
        }
    }
}
`