import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const CountryContainer = styled(NavLink)`
    background: ${p => p.theme["white"]};
    padding: 0 12px;
    border-radius: 8px;
    border: 1px solid ${p => p.theme["gray-200"]};
    box-shadow: none;

    display: flex;
    flex-direction: row;
    align-items: center;
    filter: brightness(0.98);
    transition: box-shadow 100ms, filter 100ms, outline 50ms;

    &:hover {
        filter: brightness(1.05);
        box-shadow: 0px 84px 34px rgba(105, 109, 119, 0.01),
                    0px 48px 29px rgba(105, 109, 119, 0.03),
                    0px 21px 21px rgba(105, 109, 119, 0.04),
                    0px 5px 12px rgba(105, 109, 119, 0.05),
                    0px 0px 0px rgba(105, 109, 119, 0.05);
    }

    img {
        position: relative;
        width: 45px;
        height: 32px;
        object-fit: contain;
        border-radius: 4px;
        margin-right: 32px;
    }

    &:first-child img::after {
        content: "dskuhd";
        width: 10px;
        height: 10px;
        background-color: white;
    }

    h2 {
        flex: 1;
        min-width: 150px;
        padding: 16px 24px;
        color: ${p => p.theme["black"]};
        font-size: ${p => p.theme["text-xl"]};
        border: solid ${p => p.theme["gray-200"]};
        border-width: 0px 1px 0px 1px;
    }

    span {
        flex: 1;
        min-width: 150px;
        padding: 16px 24px;
        color: ${p => p.theme["black"]};
        font-size: ${p => p.theme["text-md"]};
    }

    &:focus {
        text-decoration: none;
        outline-color: ${p => p.theme["blue-500"]};
    }

    @media (max-width: 481px) {
        overflow: auto;
        overflow: overlay;

        &::-webkit-scrollbar {
            display: none;
        }
    }
`

export const CountriesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

export const MainContainer = styled.main`
    width: calc(100% - 40px);
    max-width: 1000px;
    margin: auto;

    display: flex;
    flex-direction: column;
    gap: 30px;
`