import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

export function usePath() {
    const reactLocation = useLocation()
    const [currentPath, setCurrentPath] = useState(reactLocation.pathname.split("/")[1])

    useEffect(() => {
        const currentLocation = window.location.pathname.split("/")[1]

        setCurrentPath(prev => {
            return prev === currentLocation ? prev : currentLocation
        })
    }, [reactLocation, setCurrentPath])

    switch(currentPath) {
        case "" || "/":
            return "/"
            break
        case "comunidade":
            return "/comunidade"
            break
        case "loja":
            return "/loja"
            break
        case "halldafama":
            return "/halldafama"
            break
        default:
            return "/"
            break
    }
}