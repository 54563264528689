import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const ArticleInfos = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    h3 {
        color: ${p => p.theme["gray-800"]};
        font-size: ${p => p.theme["text-lg"]};
        font-weight: bold;

        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        margin: 0;
    }
    
    span {
        color: ${p => p.theme["gray-800"]};
        font-size: ${p => p.theme["text-sm"]};

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 5px 0 0 0;
    }
`

export const ArticleTopstory = styled.img`
    width: 100%;
    height: 170px;
    object-fit: cover;
    object-position: center;
    image-rendering: pixelated;

    border-radius: 5px;
`

export const ArticleContainer = styled(NavLink)`
    background: ${p => p.theme["gray-200"]};
    padding: 10px;
    width: 320px;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    gap: 10px;

    border-radius: 6px;
    position: relative;
    top: 0;
    transition: all 150ms, outline 50ms;
    cursor: pointer;

    &:hover {
        background: ${p => p.theme["white"]};
        box-shadow: 0px 13px 10px -10px rgba(0, 0, 0, 0.25);
        top: -2px;
    }
`