
import React, { useContext } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { HeaderLogged } from "../../components/Header/loggedHeader";
import { HeaderUnlogged } from "../../components/Header/unloggedHeader"
import { LayoutContainer } from "./style";
import { usePath } from "../../hooks/usePath";
import { UserContext } from "../../Contexts/UserContext";
import Footer from "../../components/footer";

export function DefaultLayout() {
    const {userInfos: {isLogged, nickname}} = useContext(UserContext)
    const page = usePath();
    return (
        <LayoutContainer>
            {isLogged ? <HeaderLogged page={page} nickname={nickname} /> : <HeaderUnlogged />}
            <Outlet />
            <Footer/>
            <ScrollRestoration />
        </LayoutContainer>
    )
}