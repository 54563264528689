import React from 'react';
import { Box, BoxTitle } from "../../../styles/global";
import { RankingCard, UserInfoCol, UserItem, UsersList } from "../style";

export function Promotions() {
    return (
        <Box>
            <BoxTitle variant="colorful" color="blue">
                Promoções
            </BoxTitle>

            <UsersList>
                <UserItem>
                    <img src="https://habblive.in/imageeer.php?user=Batman&action=wav&size=b&head_direction=3&direction=3&gesture=sml" alt="" />
                    <UserInfoCol>
                        <strong>Batman</strong>
                        <span>19 promoções</span>
                    </UserInfoCol>
                    <RankingCard variant="gold">1</RankingCard>
                </UserItem>

                <UserItem>
                    <img src="https://habblive.in/imageeer.php?user=Batman&action=wav&size=b&head_direction=3&direction=3&gesture=sml" alt="" />
                    <UserInfoCol>
                        <strong>Batman</strong>
                        <span>19 promoções</span>
                    </UserInfoCol>
                    <RankingCard variant="silver">2</RankingCard>
                </UserItem>
                
                <UserItem>
                    <img src="https://habblive.in/imageeer.php?user=Batman&action=wav&size=b&head_direction=3&direction=3&gesture=sml" alt="" />
                    <UserInfoCol>
                        <strong>Batman</strong>
                        <span>19 promoções</span>
                    </UserInfoCol>
                    <RankingCard variant="bronze">3</RankingCard>
                </UserItem>

                <UserItem>
                    <img src="https://habblive.in/imageeer.php?user=Batman&action=wav&size=b&head_direction=3&direction=3&gesture=sml" alt="" />
                    <UserInfoCol>
                        <strong>Batman</strong>
                        <span>19 promoções</span>
                    </UserInfoCol>
                    <RankingCard variant="normal">4</RankingCard>
                </UserItem>

                <UserItem>
                    <img src="https://habblive.in/imageeer.php?user=Batman&action=wav&size=b&head_direction=3&direction=3&gesture=sml" alt="" />
                    <UserInfoCol>
                        <strong>Batman</strong>
                        <span>19 promoções</span>
                    </UserInfoCol>
                    <RankingCard variant="normal">5</RankingCard>
                </UserItem>
            </UsersList>
        </Box>
    )
}