import styled from "styled-components";

export const FaqContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

    padding: 8px;
    background: ${p => p.theme["gray-100"]};
    border-radius: 4px;

    & + & {
        margin-top: 4px;
    }

    h4 {
        font-size: ${p => p.theme["text-sm"]};
        color: ${p => p.theme["black"]};
        font-weight: 700;
    }

    p {
        font-size: ${p => p.theme["text-sm"]};
        color: ${p => p.theme["black"]};
    }

    a {
        font-size: ${p => p.theme["text-sm"]};
        font-weight: bold;
        color: ${p => p.theme["blue-500"]};
        border-radius: 1px;
        cursor: pointer;

        &:hover {
            color: ${p => p.theme["blue-700"]};
        }

        &:focus {
            color: ${p => p.theme["blue-700"]};
            outline-color: ${p => p.theme["blue-500"]};
            outline-offset: 2px;
            transition: outline 50ms ease-in;
        }

        &:active {
            filter: brightness(0.95);
        }
    }
`

export const AsideContainer = styled.aside`
    display: flex;
    flex-direction: column;
    gap: 30px;
`

export const MainContainer = styled.main`
    display: flex;
    flex-direction: column;
    gap: 30px;
`

export const PurchaseVipPageLayout = styled.div`
    display: grid;
    grid-template-columns: 600px 1fr;
    gap: 30px;

    width: calc(100% - 40px);
    max-width: 1000px;
    margin: auto;

    @media (max-width: 1040px) {
        grid-template-columns: 60% 1fr;
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`