import React from 'react';
import { AsideContainer, FaqContainer, PurchasePackageLayout, MainContainer } from "./style";

import { ProductCard } from "./Components/ProductCard";
import { BadgesRow, Box, BoxContent, BoxTitle } from "../../styles/global";

import { ShoppingCart } from "../../components/ShoppingCart";

export function PurchasePackage() {
    return (
        <PurchasePackageLayout>
            <MainContainer>

                <ProductCard image="/plano-azul.png" packName="Pacote Azul" titleColor="blue" price="R$ 25,00">
                    <ul>
                        <li>12 super raros da sua escolha</li>
                    </ul>
                </ProductCard>
                
                <ProductCard image="/plano-laranja.png" packName="Pacote Laranja" titleColor="orange" price="R$ 55,00">
                    <ul>
                        <li>30 super raros da sua escolha</li>
                    </ul>
                </ProductCard>

                <ProductCard image="/plano-vermelho.png" packName="Pacote Vermelho" titleColor="red" price="R$ 105,00">
                    <ul>
                        <li>8 hiper raros da sua escolha</li>
                    </ul>
                </ProductCard>

                <ProductCard image="/plano-branco.png" packName="Pacote Branco" titleColor="white" price="R$ 35,00">
                    <ul>
                        <li>1 totem da sua escolha</li>
                    </ul>
                </ProductCard>

                <ProductCard image="/plano-branco-2.png" packName="Pacote Branco 2" titleColor="white" price="R$ 220,00">
                    <ul>
                        <li>13 totens da sua escolha</li>
                    </ul>
                </ProductCard>

            </MainContainer>

            <AsideContainer>

                {/* Carrinho de compras */}
                <ShoppingCart />

                {/* Planos de raros */}
                <Box>
                    <BoxTitle variant="colorful">
                        Planos de raros
                    </BoxTitle>

                    <BoxContent>
                        <p>
                            Ostente os mais exclusivos raros do hotel! Basta você escolher o pacote que melhor se aplica ao que desejas.
                        </p>

                        <p>
                            Além de tudo, você pode obter emblemas exclusivos de acordo com a quantidade de pacotes que você compra!
                        </p>

                        <BadgesRow>
                            <img src="/emblema-plano-1.png" alt="" />
                            <img src="/emblema-plano-2.png" alt="" />
                            <img src="/emblema-plano-3.png" alt="" />
                        </BadgesRow>
                    </BoxContent>
                </Box>

                {/* FAQs */}
                <Box>
                    <BoxTitle variant="colorful">
                        FAQs
                    </BoxTitle>

                    <BoxContent>
                        <FaqContainer>
                            <h4>Como escolho os raros?</h4>

                            <p>
                                Ao clicar em adicionar ao carrinho, você poderá escolher o que deseja dentro do nosso catálogo de raros.
                            </p>
                        </FaqContainer>

                        <FaqContainer>
                            <h4>Como receberei meus raros?</h4>
                            
                            <p>
                                Após pagar os seus planos, <a href="">clique aqui</a> para enviar seu comprovante de pagamento. Após isso, basta aguardar que o gerente ou CEO lhe entregue seus raros.
                            </p>
                        </FaqContainer>

                        <FaqContainer>
                            <h4>Qual prazo para receber?</h4>

                            <p>
                                Você receberá os seus itens em até 48h após enviar o comprovante.
                            </p>
                        </FaqContainer>

                        <FaqContainer>
                            <h4>Como consigo suporte?</h4>

                            <p>
                                Se nenhuma das respostas acima sanaram sua dúvida, você pode entrar em contato conosco através do seguinte e-mail: <strong>contato@habblive.online</strong>.
                            </p>
                        </FaqContainer>

                        <FaqContainer>
                            <h4>Como posso receber reembolso?</h4>

                            <p>
                                A equipe Habblive não reembolsará nenhuma compra.
                            </p>
                        </FaqContainer>
                    </BoxContent>
                </Box>

            </AsideContainer>
        </PurchasePackageLayout>
    )
}