import * as PrimitiveSelect from "@radix-ui/react-select"
import styled from "styled-components"


export const Separator = styled(PrimitiveSelect.Separator)`
    height: 1px;
    background: ${p => p.theme["gray-200"]};
    margin-bottom: 4px;
    border-radius: 999px;
`

// indicates the selected

export const ItemText = styled(PrimitiveSelect.ItemText)``

export const Item = styled(PrimitiveSelect.Item)`
    padding: 6px 24px;
    border-radius: 4px;
    color: ${p => p.theme["gray-600"]};
    font-size: ${p => p.theme["text-sm"]};
    font-weight: 500;
    cursor: default;

    &:hover {
        color: ${p => p.theme["blue-700"]};
        background: rgba(0, 167, 239, 0.25);
        outline: none;

        transition: background 50ms;
    }

    &:focus {
        color: ${p => p.theme["blue-700"]};
        background: rgba(0, 167, 239, 0.25);
        box-shadow: inset 0px 0px 0px 2px rgba(0, 167, 239, 0.05);
        outline: none;
        
        transition: background 50ms;
    }

    &[data-disabled] {
        color: ${p => p.theme["gray-700"]};
    }

    &[data-state="checked"] {
        background: ${p => p.theme["gray-200"]};
        color: ${p => p.theme["black"]};
    }
`

// content start ----

// selectitem is actually another function

export const Label = styled(PrimitiveSelect.Label)`
    font-size: ${p => p.theme["text-xs"]};
    color: ${p => p.theme["gray-500"]};
    padding: 0 24px;
    margin-bottom: 2px;
    cursor: default;
`

export const Group = styled(PrimitiveSelect.Group)`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

export const Viewport = styled(PrimitiveSelect.Viewport)``

export const ScrollDownButton = styled(PrimitiveSelect.ScrollDownButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px
`

export const ScrollUpButton = styled(ScrollDownButton).attrs({
    as: PrimitiveSelect.ScrollUpButton
})``

export const Content = styled(PrimitiveSelect.Content)``

export const Portal = styled(PrimitiveSelect.Portal)`
    background: #FFFFFF;
    border: 1px solid ${p => p.theme["gray-300"]};
    box-shadow: -2px 20px 8px rgba(0, 0, 0, 0.01), -1px 11px 7px rgba(0, 0, 0, 0.03), 0px 5px 5px rgba(0, 0, 0, 0.04), 0px 1px 3px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 24px 12px;
`

// content end ----

export const Icon = styled(PrimitiveSelect.Icon)``

export const Value = styled(PrimitiveSelect.Value)``

export const Trigger = styled(PrimitiveSelect.Trigger)`
    all: unset;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    width: fit-content;
    padding: 0 20px;
    height: 51px;
    background: ${p => p.theme["gray-100"]};
    border-radius: 5px;

    span {
        font-size: ${p => p.theme["text-sm"]};
        font-weight: bold;
        color: ${p => p.theme["gray-900"]};
    }

    span:has(svg) {
        line-height: 0;
    }

    &:focus {
        background: ${p => p.theme["white"]};
        outline: 2px solid ${p =>p .theme["gray-400"]};
        transition: background 150ms, outline 50ms;
    }

    @media (max-width: 481px) {
        display: inline-flex;
    }
`

export const Select = styled(PrimitiveSelect.Root)`
`