import { NavLink } from "react-router-dom"
import styled from "styled-components"
import { BoxTitle } from "../../styles/global"

export const ArticleContentSkeleton = styled.div`
    height: 100vh;
`

export const AsideContainer = styled.aside`
    display: flex;
    flex-direction: column;
    gap: 30px;
`

export const LabelChip = styled.span`
    padding: 5px 8px;
    background-color: ${p => p.theme["gray-500"]};
    border-radius: 4px;

    color: ${p => p.theme["white"]};
    font-size: ${p => p.theme["text-xs"]};
    font-weight: bold;
    text-transform: capitalize;
`

export const ArticleAuthorInfos = styled.div`
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 4px;
    align-self: center;

    cursor: default;

    a, strong {
        font-weight: bold;
        font-size: ${p => p.theme["text-md"]};
        color: ${p => p.theme["blue-500"]};
    }

    span {
        font-size: ${p => p.theme["text-sm"]};
        font-weight: 400;
        color: ${p => p.theme["black"]};
    }
`

export const ArticleFooter = styled.footer`
    display: flex;
    gap: 16px;
    padding: 16px;
    border-top: 1px solid ${p => p.theme["gray-300"]};

    > img {
        width: fit-content;
        height: 60px;
        object-fit: none;
        object-position: 50% 30%;
        box-sizing: content-box;
        padding: 8px;
        padding-bottom: 0;
        border-radius: 6px;
        background: ${p => p.theme["gray-100"]};
    }
`

export const ArticleHeader = styled(BoxTitle).attrs({
    as: "header",
})`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    margin: 0;
    margin-bottom: 16px;
    user-select: auto;
`

export const ArticleContent = styled.div`

    > :is(p, div, img, blockquote, table, center) {
        margin: 0 16px 16px 16px;
    }

    p, div, span, strong {
        font-weight: 400;
        font-size: ${p => p.theme["text-sm"]};
        color: ${p => p.theme["black"]};
    }

    img { max-width: calc(100% - 32px) }

    strong { font-weight: bold; }

    blockquote {
        padding: 12px;
        border-radius: 0 4px 4px 0;
        background: ${p => p.theme["gray-100"]};
        border-left: 4px solid ${p => p.theme["yellow-500"]};
        box-shadow: none;
    }

    hr {
        border: none;
        width: 100%;
        height: 1px;
        background: ${p => p.theme["gray-300"]};
        margin-bottom: 16px;
    }

    table {
        max-width: 100%;
        overflow: hidden;
    }
`

export const MainContainer = styled.main`
    display: flex;
    flex-direction: column;
    gap: 30px;
`

export const ArticlePageLayout = styled.div`
    width: calc(100% - 40px);
    max-width: 1000px;
    margin: auto;

    display: grid;
    grid-template-columns: 600px 1fr;
    gap: 30px;

    @media (max-width: 1040px) {
        grid-template-columns: 60% 1fr;

        ${MainContainer} {
            grid-template-columns: 1fr;
        }
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;

        ${AsideContainer} {
            a {
                padding: 12px;
                font-size: ${p => p.theme["text-md"]};
            }
        }
    }
`