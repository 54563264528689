import styled, { css } from "styled-components";
import { Box } from "../../styles/global";

type RankingCard = {
    variant: "gold" | "silver" | "bronze" | "normal"
}

export const RichestUsersWrapper = styled.div`
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

        
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`

export const RichestUsersContainer = styled.section`
    grid-area: RichestUsers;
`

export const BadgesRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;

    @media (max-width: 320px) {
        align-self: center;
        flex-wrap: wrap;
    }
`

export const InfoContentFooter = styled.footer`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    border-top: 1px solid ${p => p.theme["gray-300"]};

    @media (max-width: 320px) {
        flex-direction: column;
        gap: 12px;
    }
`

export const InfoContentCol = styled.article`
    margin: 16px 0;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    img {
        max-width: 100%;
    }
`

export const InfosContainer = styled(Box).attrs({
    as: "section"
})`
    grid-area: Info;
    align-self: flex-start;
    display: flex;
    flex-direction: column;

    font-size: ${p => p.theme["text-sm"]};
    color: ${p => p.theme["black"]}
`


export const RankingCard = styled.span<RankingCard>`
    font-size: ${p => p.theme["text-lg"]};
    font-weight: 700;
    ${p => p.variant === "silver" || p.variant === "normal" ? css`
        color: ${p => p.theme["black"]};
        ` : css`    
        color: ${p => p.theme["white"]};
    `}

    width: 40px;
    height: 40px;
    border-radius: 4px;
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
    flex-shrink: 0;
    display: grid;
    place-items: center;

    ${p => p.variant === "gold" ? css`background: ${p => p.theme["yellow-500"]}` : null}
    ${p => p.variant === "silver" ? css`background: ${p => p.theme["white"]}` : null}
    ${p => p.variant === "bronze" ? css`background: ${p => p.theme["orange-500"]}` : null}
    ${p => p.variant === "normal" ? css`background: ${p => p.theme["gray-200"]}` : null}
`

export const UserInfoCol = styled.div`
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 0;

    strong {
        color: ${p => p.theme["black"]};
        font-size: ${p => p.theme["text-md"]};
        font-weight: 700;
    }

    span {
        color: ${p => p.theme["gray-800"]};
        font-size: ${p => p.theme["text-sm"]};
        font-weight: 400;
    }

    @media (max-width: 768px) {
        min-width: 150px;
    }
`

export const UserItem = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    padding: 0 10px 2px 10px;

    &:nth-child(even) { background: ${p => p.theme["list-even-200"]}; }
    &:nth-child(odd) { background: ${p => p.theme["list-odd-200"]}; }

    &:not(:last-child) {
        border-bottom: 1px solid ${p => p.theme["gray-300"]};
    }

    &:last-child {
        padding-bottom: 10px;
    }

    img {
        height: 60px;
        object-fit: none;
        object-position: top;
    }

    @media (max-width: 720px) {
        max-width: 100%;
        overflow: auto;
        overflow: overlay;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    @media (max-width: 320px) {
        padding: 10px 10px;

        img {
            display: none;
        }
    }
`

export const UsersList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0;

    max-width: 100%;
`

export const HallOfFameContainer = styled.section`
    grid-area: Hall;

    display: flex;
    flex-direction: column;
    gap: 30px;
`

export const HallOfFamePageLayout = styled.main`
    width: calc(100% - 40px);
    max-width: 1000px;
    margin: auto;

    display: grid;
    grid-template-areas: "Hall Info" "RichestUsers RichestUsers";
    grid-auto-columns: 1fr;
    row-gap: 50px;
    column-gap: 30px;

    @media (max-width: 768px) {
        grid-template-areas: "Info" "Hall" "RichestUsers";
        grid-auto-columns: 100%;
    }
`
