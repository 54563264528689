import React from 'react';
import { Button } from "../../../../components/Button";
import { Input } from "../../../../styles/global";
import { ButtonsRow, Form } from "../style";

type FirstStepProps = {
    nextStepFunction: (value: number) => void;
}

export function EmailFirstStep(props: FirstStepProps) {

    function handleSubmit() {
        props.nextStepFunction(1)
    }

    return (
        <Form onSubmit={handleSubmit}>

            <label htmlFor="currentEmail">
                E-mail atual
            </label>
            <Input
                name="curretEmail"
                id="curretEmail"
                value={"emailatual@gmail.com"}
                placeholder={"email@email.com"}
                disabled
            />

            <label htmlFor="newEmail">
                Novo e-mail
            </label>
            <Input
                name="newEmail"
                id="newEmail"
                placeholder={"email@email.com"}
            />

            <label htmlFor="confirmNewEmail">
                Repita o novo e-mail
            </label>
            <Input
                name="confirmNewEmail"
                id="confirmNewEmail"
                placeholder={"email@email.com"}
            />

            <ButtonsRow>
                <Button type="submit" variant="success">enviar código de verificação</Button>
            </ButtonsRow>

        </Form>
    )
}