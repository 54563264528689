import styled from "styled-components"

type productHeaderProps = {
    color: "blue" | "orange" | "red" | "white";
}

export const ProductHeader = styled.header<productHeaderProps>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 12px;

    h3 {
        border-radius: 999px;
        box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
        padding: 12px 24px;
        font-size: ${p => p.theme["text-md"]};
        color: ${p => p.theme["white"]};
        color: ${p => p.color === "white" && p.theme["black"]};
        
        // background options
        background: ${p => p.color === "blue" && p.theme["blue-500"]};
        background: ${p => p.color === "orange" && p.theme["orange-500"]};
        background: ${p => p.color === "red" && p.theme["danger-500"]};
        background: ${p => p.color === "white" && p.theme["white"]};
    }

    span {
        font-weight: bold;
        font-size: ${p => p.theme["text-sm"]};
        background: ${p => p.theme["black"]};
        border-radius: 999px;
        height: fit-content;
        padding: 8px 16px;
        color: ${p => p.theme["white"]};
    }

    @media (max-width: 481px) {
        h3 {
            flex: 1;
        }
    }
`

export const ProductInfosContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    p, ul, li {
        font-size: ${p => p.theme["text-sm"]};
        font-weight: regular;
        color: ${p => p.theme["black"]};
    }

    li {
        list-style: none;

        &::before {
            content: "•";
            margin-right: 6px;
            color: ${p => p.theme["black"]};
        }
    }

    button {
        width: fit-content;
    }

    @media (max-width: 481px) {
        align-items: center;

        p, ul, li {
            width: 100%;
        }
    }
`

export const ProductContainer = styled.article`
    width: 100%;
    padding: 12px;
    background: ${p => p.theme["white"]};
    border: 1px solid ${p => p.theme["gray-300"]};
    border-radius: 8px;

    display: flex;
    flex-direction: row;
    gap: 16px;

    img {
        width: 150px;
        min-height: 150px;
        border: 0;
        border-radius: 8px;
        background: ${p => p.theme["gray-100"]};

        object-fit: none;
        object-position: center;
    }

    @media (max-width: 481px) {
        flex-direction: column;
        align-items: center;
        
        button {
            width: 100%;
        }

        img {
            flex: 1;
            width: 100%;
            max-height: 145px;
        }
    }
`