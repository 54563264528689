import styled, { css } from "styled-components";

type StatusBadgeProps = {
    isOnline: boolean;
}

export const AsideContainer = styled.aside`
    display: flex;
    flex-direction: column;
    gap: 30px;
`

export const StatusBadge = styled.span<StatusBadgeProps>`
    display: block;
    margin-top: 8px;
    width: fit-content;
    border-radius: 999px;

    font-weight: 700;
    font-size: ${p => p.theme["text-sm"]};
    color: ${p => p.theme["white"]};
    padding: 4px 16px;

    user-select: none;

    /* make its color change according to the boolean value */
    ${p => p.isOnline ?
        css`background: ${p => p.theme["green-500"]};`
        :
        css`background ${p => p.theme["black"]};`
    }
`

export const InfosContainer = styled.div`
    h4 {
        color: ${p => p.theme["black"]};
        font-weight: bold;
        font-size: ${p => p.theme["text-sm"]};
        margin-bottom: 8px;
    }

    span:not(${StatusBadge}) {
        display: block;
        font-size: ${p => p.theme["text-sm"]};
        color: ${p => p.theme["gray-800"]};
        font-weight: 400;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
		word-break: break-word;

        strong { font-weight: bold; }

        &:not(${StatusBadge}) + span:not(${StatusBadge}) { margin-top: 4px; }
    }
`

export const VipCard = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    padding: 8px;
    height: fit-content;

    background: ${p => p.theme["white"]};
    outline: 1px solid ${p => p.theme["gray-300"]};
    outline-offset: -1px;
    border-radius: 8px;

    img {
        background: ${p => p.theme["gray-100"]};
        border-radius: 6px;
        width: fit-content;
        height: 100px;
        object-fit: none;
        object-position: bottom;
    }
`

export const MainContainer = styled.main`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: min-content;
    gap: 16px;
`

export const VipPageLayout = styled.div`
    width: calc(100% - 40px);
    max-width: 1000px;
    margin: auto;

    display: grid;
    grid-template-columns: 600px 1fr;
    gap: 30px;

    @media (max-width: 1040px) {
        grid-template-columns: repeat(2, 1fr);

        ${MainContainer} {
            grid-template-columns: 1fr;
        }
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;

        ${AsideContainer} {
            grid-row: 1;

            a {
                padding: 12px;
                font-size: ${p => p.theme["text-md"]};
            }
        }
    }
`