import styled from "styled-components";

export const ButtonLayout = styled.button`
    background: ${p => p.theme["gray-1000"]};
    box-shadow: 0px 15px 10px -10px rgba(0, 0, 0, 0.25);
    border: 0;
    border-radius: 3px;

    padding: 10px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-50%);
    transition: transform 150ms;

    svg {
        color: ${p => p.theme["white"]};
    }

    &:hover {
        background: ${p => p.theme["green-700"]};
        transform: translateY(calc(-50% - 3px));
    }
`