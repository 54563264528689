import styled from "styled-components";

export const Amount = styled.span`
    display: block;
    flex: 1;
    color: ${p => p.theme["gray-600"]};
    font-size: ${p => p.theme["text-sm"]};
    font-weight: 700;
`

export const UserInfos = styled.div`
    flex: 1;
    display: flex;
    gap: 4px;
    align-items: center;

    img {
        image-rendering: pixelated;
    }

    span {
        color: ${p => p.theme["gray-600"]};
        font-size: ${p => p.theme["text-sm"]};
        font-weight: 700;
    }

    @media (max-width: 481px) {
        img { display: none; }
    }
`

export const UsersCard = styled.li`
    list-style: none;
    width: 100%;
    padding: 8px 12px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 6px;

    display: flex;
    align-items: center;
    gap: 12px;

    &:nth-child(odd) { background: ${p => p.theme["list-odd-200"]}; }
    &:nth-child(even) { background: ${p => p.theme["list-even-200"]}; }

    &:hover {
        filter: brightness(0.97);
    }

    @media (max-width: 481px) {
        padding: 16px 12px;
    }
`

export const UsersCol = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 2px;
`

export const Header = styled.header`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-top: 12px;

    h2 {
        flex: 1;
        font-size: ${p => p.theme["text-md"]};
        font-weight: 700;
        color: ${p => p.theme["black"]}
    }
`