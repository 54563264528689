import React from "react"
import { Close, Container, Content, Description, Header, Overlay, Portal, Title, Trigger } from "./styleDialog"
import { FaBars } from "react-icons/fa"
import { CloseButton } from "../../../styles/global"
import { FaTimes } from "react-icons/fa"
import { LoggedMobileNavigation, UnloggedMobileNavigation } from "./MobileNavigation"

export function OpenMenuButton() {
    return (
        <Trigger>
            <FaBars size={16} /> Abrir Menu
        </Trigger>
    )
}

type MobileNavigationProps = {
    isLogged: boolean;
}

export function MobileNavDialog(props: MobileNavigationProps) {
    return (
        <Container modal>
            <OpenMenuButton />

            <Portal>
                <Overlay />
                <Content>
                    <Header>
                        <Title>Menu de navegação</Title>
                        <Description>Área de navegação para encontrar todas as páginas do site.</Description>

                        <Close asChild>
                            <CloseButton type="button" aria-label="Close"><FaTimes size={20} /></CloseButton>
                        </Close>
                    </Header>

                    {props.isLogged === true && <LoggedMobileNavigation />}
                    {props.isLogged === false && <UnloggedMobileNavigation />}
                </Content>
            </Portal>
        </Container>
    )
}