import styled from "styled-components";
import * as PrimitiveSwitch from "@radix-ui/react-switch"

export const SwitchThumb = styled(PrimitiveSwitch.Thumb)`
    background: ${p => p.theme["gray-600"]};
    border-radius: 7px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    width: 20px;
    height: 20px;

    transform: translateX(-10px);
    transition: all 150ms ease-in;

    &[data-state="checked"] {
        background: ${p => p.theme["white"]};

        transform: translateX(10px);
    }
`

export const SwitchBody = styled(PrimitiveSwitch.Root)`
    padding: 5px;
    border-radius: 10px;
    box-shadow: inset 0px 0px 0px 1px ${p => p.theme["gray-400"]};
    background: ${p => p.theme["gray-200"]};

    width: 50px;
    flex-shrink: 0;
    position: relative;
    transition: all 150ms ease-in;

    &:hover {
        background: ${p => p.theme["gray-300"]};
    }

    &[data-state="checked"] {
        background: ${p => p.theme["green-500"]};
        box-shadow: inset 0px 0px 0px 1px ${p => p.theme["green-700"]};

        &:hover {
            filter: brightness(0.9);
        }
    }

    :focus {
        outline-color: ${p => p.theme["black"]};
        transition: outline 50ms;
    }
`

export const SwitchRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 8px;

    label {
        font-weight: 400;
        font-size: ${p => p.theme["text-md"]};
        color: ${p => p.theme["gray-900"]};
        display: block;
        flex: 1;
    }

    padding: 0 10px 10px 10px;
    border-bottom: 1px solid ${p => p.theme["gray-300"]};
`