import * as Tooltip from "@radix-ui/react-tooltip"
import styled, { keyframes } from "styled-components"

const SlideToTop = keyframes`
    from {
        opacity: 0;
        transform: translateY(0);
    }

    to {
        opacity: 1;
        transform: translateY(-2px);
    }
`

export const Provider = styled(Tooltip.Provider)``

export const Root = styled(Tooltip.Root)``

export const Trigger = styled(Tooltip.Trigger)``

export const Portal = styled(Tooltip.Portal)``

export const Content = styled(Tooltip.Content)`
    padding: 10px;
    background: #000000e6;
    user-select: none;

    font-size: ${p => p.theme["text-sm"]};
    color: ${p => p.theme["white"]};
    font-weight: 700;
    border-radius: 5px;

    opacity: 0;
    transform: translateY(0);
    transition: opacity .3s cubic-bezier(0.16, 1, 0.3, 1), transform .3s cubic-bezier(0.16, 1, 0.3, 1);

    &[data-state="delayed-open"], &[data-state="instant-open"] {
        animation: ${SlideToTop} .3s ease-out;
        opacity: 1;
        transform: translateY(-2px);
    }
`

export const Arrow = styled(Tooltip.Arrow)``