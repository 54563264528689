import React from 'react';
import { FullTitle } from "../../components/FullTitle";
import { CountriesWrapper, CountryContainer, MainContainer } from "./style";
import CountryDatas from "./countrydata.json"

/*
# Tipagem
    countryName: string;
    flagUrl: string;
    onlineUsersTotal: number;
    users: {
        nickname: string;
        motto: string;
        lastLogIn: string;
        ip: string;
        city: string;
        isUsingVpn: boolean;
    }[]
*/


export function Countries() {

    return (
        <MainContainer>
            <FullTitle>Países online</FullTitle>

            <CountriesWrapper>
                {CountryDatas.map(country => {
                    const path = country.countryName.toLowerCase().replaceAll(" ", "")
                    return (
                        <CountryContainer key={country.countryName} to={path}>
                            <img src={country.flagUrl} alt="" />

                            <h2>{country.countryName}</h2>

                            <span>{`${country.onlineUsersTotal} usuários online.`}</span>
                        </CountryContainer>
                    )
                })}
            </CountriesWrapper>
        </MainContainer>
    )
}