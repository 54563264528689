import React from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { HeaderContainer, HeaderContents, MDNavigationMenu, MenuLinks, NavigationContainer, OnlineUsersContainer, OnlineUsersTooltip, SMNavigationMenu, SubNavContainer } from "./styles";
import { MobileNavDialog } from "./subcomponents/DialogElements";

type HeaderType = {
    page: "/" | "/comunidade" | "/loja" | "/halldafama";
    nickname: string;
}

type LinkType = {
    label: string,
    path: string,
    external: boolean,
    destructive?: boolean,
}

type SubNavPathesType = {
    "/": LinkType[];
    "/comunidade": LinkType[];
    "/loja": LinkType[];
    "/halldafama": LinkType[];
}


export const subNavPathes: SubNavPathesType = {
    "/": [
        {
            label: "Home",
            path: "/",
            external: false
        },
        {
            label: "Configurações",
            path: "/configs",
            external: false
        },
        {
            label: "Perfil",
            path: "/perfil",
            external: false
        },
        {
            label: "Calendário de Atividades",
            path: "https://hlive.ovh/calendario",
            external: true
        },
        {
            label: "Promoções Ativas",
            path: "/noticia/promocoesativas",
            external: false
        },
        {
            label: "Colunas Semanais",
            path: "/noticias/colunassemanais",
            external: false
        },
        {
            label: "Comunidade no Discord",
            path: "https://discord.com/app",
            external: true
        },
        {
            label: "Sair",
            path: "/logout",
            external: false,
            destructive: true,
        }
    ],
    "/comunidade": [
        {
            label: "Vip",
            path: "/comunidade",
            external: false
        },
        {
            label: "Staff",
            path: "/comunidade/staff",
            external: false
        },
        {
            label: "Colaboração",
            path: "/comunidade/colab",
            external: false
        },
        {
            label: "GEA",
            path: "/comunidade/gea",
            external: false
        },
        {
            label: "Oficiais",
            path: "/comunidade/oficiais",
            external: false
        },
        {
            label: "Notícias",
            path: "comunidade/noticia",
            external: false
        },
        {
            label: "Arquivo de Notícias",
            path: "/comunidade/arquivo",
            external: false
        },
        {
            label: "Valores",
            path: "/comunidade/valores",
            external: false
        },
        {
            label: "Países",
            path: "/comunidade/paises",
            external: false,
        },
        {
            label: "Server do Discord",
            path: "https://discord.com/app",
            external: true
        }
    ],
    "/loja": [
        {
            label: "Pacotes de raros",
            path: "/loja",
            external: false
        },
        {
            label: "Planos de VIP",
            path: "/loja/planosdevip",
            external: false
        },
        {
            label: "Mudança de nickname",
            path: "/loja/mudarnickname",
            external: false
        }
    ],
    "/halldafama": [
        {
            label: "Hall da Fama",
            path: "/halldafama",
            external: false
        },
    ],
}


export function HeaderLogged({page = "/", nickname}: HeaderType) {

    const currentSubnavLinks = subNavPathes[page]
    const wavingUser = `https://habblive.in/imageeer.php?user=${nickname}&action=wav&size=b&head_direction=3&direction=3&gesture=sml`

    return (
        <HeaderContainer>
        <HeaderContents>


                {/* menu de navegação para desktop */}
                <NavLink to="/" className="logo">
                    <img src="/logo.png" id="logo" alt="Habblive" />
                </NavLink>

                <MDNavigationMenu>
                    <NavigationContainer>
                        <MenuLinks>
                            <NavLink to="/" end>{nickname}</NavLink>
                            <NavLink to="/comunidade">Comunidade</NavLink>
                            <NavLink to="/loja">Loja</NavLink>
                            <NavLink to="/halldafama">Hall da Fama</NavLink>
                            <NavLink className="secondary" to="/betaclientv2">Jogar</NavLink>
                        </MenuLinks>
                    </NavigationContainer>

                    <SubNavContainer>
                        {currentSubnavLinks.map((each: LinkType) => {
                            return (
                                each.external === true ?
                                    <a key={each.label} target="_blank" href={each.path}>{each.label}</a>
                                    :
                                    each.destructive === true ?
                                        <NavLink key={each.label} className="destructive" to={each.path}>{each.label}</NavLink>
                                        :
                                        each.path === "/" || each.path === "/comunidade" || each.path === "/loja" ?
                                        <NavLink key={each.label} to={each.path} end>{each.label}</NavLink>
                                        :
                                        <NavLink key={each.label} to={each.path}>{each.label}</NavLink>
                            )
                        })}
                    </SubNavContainer>
                </MDNavigationMenu>


                {/* menu de navegação para mobile */}
                <SMNavigationMenu>
                    <MobileNavDialog isLogged={true} />
                    <OnlineUsersTooltip><strong>0000</strong> usuários!</OnlineUsersTooltip>
                </SMNavigationMenu>


                {/* contador de usuários online */}
                <OnlineUsersContainer>
                    <OnlineUsersTooltip><strong>0000</strong> usuários!</OnlineUsersTooltip>
                    <img src={wavingUser} alt="Habbo avatar wavinge" />
                </OnlineUsersContainer>


        </HeaderContents>
        </HeaderContainer>
    )
}