import React, { useContext } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { UserContext } from "./Contexts/UserContext";
import { DefaultLayout } from "./Layouts/DefaultLayout"
import { Archive } from "./pages/Archive";
import { ArticleTemplate, RedirectToLatestArticle } from "./pages/Article";
import { ColaboratorsPage } from "./pages/Colaborators";
import { ClearFriendsConfigs } from "./pages/Configs/ClearFriendsConfigs";
import { ConfigsLayout } from "./pages/Configs/ConfigsLayout";
import { EmailConfigs } from "./pages/Configs/EmailConfigs";
import { GeneralConfigs } from "./pages/Configs/GeneralConfigs";
import { PasswordConfigs } from "./pages/Configs/PasswordConfigs";
import { Countries } from "./pages/Countries";
import { CountryPage } from "./pages/Countries/ContryPage";
import { GeaPage } from "./pages/GEA";
import { HallOfFame } from "./pages/HallOfFame";
import { Home } from "./pages/Home";
import { Index } from "./pages/Index"
import { Loading } from "./pages/Loading";
import { OfficialsPage } from "./pages/Oficiais";
import { UserProfile } from "./pages/Profile";
import { PurchaseNickname } from "./pages/PurchaseNickname";
import { PurchasePackage } from "./pages/PurchasePackage";
import { PurchaseVip } from "./pages/PurchaseVip";
import { StaffPage } from "./pages/Staff";
import { Valorists } from "./pages/Valorists";
import { Vip } from "./pages/Vip"

export function Router() {
    const {userInfos: {isLogged}} = useContext(UserContext)
    
    const router = createBrowserRouter([
        {
            path: "/",
            element: <DefaultLayout />,
            children: [
                {
                    index: true,
                    element: !!isLogged ? <Home /> : <Index />,
                },
                {
                    path: "configs",
                    element: <ConfigsLayout />,
                    children: [
                        {
                            index: true,
                            element: <GeneralConfigs />,
                        },
                        {
                            path: "senha",
                            element: <PasswordConfigs />,
                        },
                        {
                            path: "email",
                            element: <EmailConfigs />
                        },
                        {
                            path: "apagaramigos",
                            element: <ClearFriendsConfigs />,
                        }
                    ]
                },
                {
                    path: "perfil",
                    element: <UserProfile />,
                },
                {
                    path: "comunidade",
                    children: [
                        {
                            index: true,
                            element: <Vip />,
                        },
                        {
                            path: "staff",
                            element: <StaffPage />
                        },
                        {
                            path: "colab",
                            element: <ColaboratorsPage />,
                        },
                        {
                            path: "gea",
                            element: <GeaPage />,
                        },
                        {
                            path: "oficiais",
                            element: <OfficialsPage />,
                        },
                        {
                            path: "noticia",
                            element: <RedirectToLatestArticle />,
                        },
                        {
                            path: "noticia/:articleId",
                            element: <ArticleTemplate />,
                        },
                        {
                            path: "arquivo",
                            element: <Archive />,
                        },
                        {
                            path: "valores",
                            element: <Valorists />,
                        },
                        {
                            path: "paises",
                            element: <Countries />,
                        },
                        {
                            path: "paises/:country",
                            element: <CountryPage />
                        },
                    ]
                },
                {
                    path: "loja",
                    children: [
                        {
                            index: true,
                            element: <PurchasePackage />,
                        },
                        {
                            path: "planosdevip",
                            element: <PurchaseVip/>
                        },
                        {
                            path: "mudarnickname",
                            element: <PurchaseNickname />
                        }
                    ]
                },
                {
                    path: "halldafama",
                    element: <HallOfFame />
                },
                {
                    path: "loadin1g",
                    element: <Loading />
                }
            ]
        }
    ])

    return (
        <RouterProvider router={router} />
    )
}

// unused: updated to new version of react router


// export function Router() {
//     const {userInfos: {isLogged}} = useContext(UserContext)

//     return (
//         <Routes>
//             <Route path="/" element={ <DefaultLayout /> }>
//                 <Route path="/" element={isLogged ? <Home /> : <Index />} />
//                 <Route path="/comunidade">
//                     <Route path="/comunidade"  element={ <Vip /> } />
//                     <Route path="staff" element={<StaffPage />} />
//                     <Route path="colab" element={<ColaboratorsPage />} />
//                     <Route path="gea" element={<GeaPage />} />
//                     <Route path="oficiais" element={<OfficialsPage />} />
//                     <Route path="noticia" element={<RedirectToLatestArticle /> } />
//                     <Route path="noticia/:articleId" element={<ArticleTemplate /> } />
//                     <Route path="arquivo" element={<Archive />} />
//                     <Route path="valores" element={<Valorists />} />
//                 </Route>
//                 <Route path="/configs" element={<ConfigsLayout />} >
//                     <Route path="/configs" element={<GeneralConfigs />} />
//                     <Route path="senha" element={<PasswordConfigs />} />
//                     <Route path="email" element={<EmailConfigs />} />
//                     <Route path="apagaramigos" element={<ClearFriendsConfigs />} />
//                 </Route>
//                 <Route path="/perfil" element={<UserProfile />} />
//                 <Route path="loja" element={<PurchasePackage />} />
//             </Route>
//         </Routes>
//     )
// }