import styled from "styled-components";
import { Box } from "../../../styles/global";

export const GoBackButton = styled.button`
    background: ${p => p.theme["blue-500"]}
`

export const ButtonsRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    
    gap: 12px;
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-top: 0;

    label + input {
        margin-top: 6px;
    }

    input + label {
        margin-top: 12px;
    }

    button {
        align-self: flex-end;
        margin-top: 10px;
    }
`

export const Container = styled(Box)`
    display: flex;
    flex-direction: column;
`