import React, { ReactNode, useEffect } from "react";
import { AccordionContent, AccordionHeader, AccordionTrigger } from "./styleAccordion";
import { FaAngleDown } from "react-icons/fa"

type HeaderType = {
    children: ReactNode;
}

export function ItemHeader(props: HeaderType) {
    return (
        <AccordionHeader>
            <AccordionTrigger>
                {props.children}
                <FaAngleDown size={16} aria-hidden />
            </AccordionTrigger>
        </AccordionHeader>
    )
}

export function ItemContent(props: HeaderType) {
    return (
        <AccordionContent>
            {props.children}
        </AccordionContent>
    )
}