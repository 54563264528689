import { Button } from "../../../../components/Button";
import { UserContext } from "../../../../Contexts/UserContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Box } from "../../../../styles/global";
import { dateFormatter } from "../../../../utils/dateFormatter";
import { ArticleAuthorInfos, ArticleContent, ArticleFooter, ArticleHeader, LabelChip } from "../../style";
import LightBox from "../LightBox"

type ArticlesProps = {
    title: string;
    description: string;
    label?: string;
    body: string;
    coverUrl: string;
    id: number;
    formId?: number;
    author: string;
    createdAt: string;
    likes: number;
}

type ArticleContainerProps = {
    articleContent: ArticlesProps | null;
}

export function ArticleComponent({articleContent}: ArticleContainerProps) {
    // articles validations
    const isNotValidArticle = articleContent === null || articleContent === undefined
    const { userInfos: {isLogged} } = useContext(UserContext)

    const userAvatar = isNotValidArticle ? "habblive" : articleContent!.author
    const avatar = `https://habblive.in/imageeer.php?user=${userAvatar}&action=wav&size=b&head_direction=2&direction=2&gesture=sml`


    // referring to lightbox element
    const lightBoxRef = useRef<HTMLDialogElement>(null)


    // add event listeners to all article images for using lightbox
    useEffect(() => {
        const articleImages = document.querySelectorAll("#articleBody img")
        articleImages.forEach(img => {
            img.addEventListener("click", openLightBox)
        })
    }, [articleContent])
    

    // lightbox states
    const [imgUrl, setImgUrl] = useState("")


    // setimg and open lightbox
    function openLightBox(e: Event) {
        const src = (e.target as HTMLImageElement).src
        setImgUrl(src)

        if(!lightBoxRef.current!.hasAttribute("open"))
            lightBoxRef.current!.showModal()

        document.querySelector("body")!.style.overflow="hidden"
    }

    return (
        <Box id="articleWrapper">
            <ArticleHeader variant="clean" id="articleHeader">
                <h2>{isNotValidArticle ? "Notícia não encontrada" : articleContent!.title}</h2>

                {isNotValidArticle ? "" : articleContent!.label! && <LabelChip>{articleContent!.label}</LabelChip>}
            </ArticleHeader>

            {isNotValidArticle ?
                <ArticleContent id="articleBody">
                    <p>Essa notícia não existe ou está inativa, sentimos muito.</p>
                </ArticleContent>
                :
                <ArticleContent id="articleBody" dangerouslySetInnerHTML={{__html: articleContent!.body}} />
            }

            <ArticleFooter id="articleFooter">
                    <img src={avatar} alt="" />

                    <ArticleAuthorInfos>
                        <strong>{isNotValidArticle ? "Autor" : articleContent!.author}</strong>
                        <span>Publicado em {isNotValidArticle ? "XX/XX/XX" : dateFormatter.format(new Date(articleContent!.createdAt))}</span>
                    </ArticleAuthorInfos>

                    { isLogged  && !isNotValidArticle &&
                        <Button variant="success" hasIcon={true} icon="thumbs-up" type="button">
                            {isNotValidArticle ? "0" : articleContent!.likes}
                        </Button>
                    }
            </ArticleFooter>
            <LightBox imgUrl={imgUrl} ref={lightBoxRef} closeModalFunction={() => {
                    lightBoxRef.current!.close()
                    setImgUrl("")
                    document.querySelector("body")!.removeAttribute("style")
                }} />
        </Box>
    )
}