import styled from "styled-components";

export const Title = styled.h2`
    font-size: ${p => p.theme["text-xl"]};
    font-weight: bold;
    color: ${p => p.theme["gray-900"]};

    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 24px;
    white-space: nowrap;
`

export const Line = styled.div`
    width: 100%;
    height: 1px;
    background: ${p => p.theme["gray-400"]};
    border-radius: 999px;
`