import React from 'react';
import { ReactNode } from "react";
import { SwitchBody, SwitchRow, SwitchThumb } from "./style";

type CustomSwitch = {
    id: string;
    children: ReactNode;
    defaultActive?: boolean
}

export function Switch({id, children, defaultActive = true}: CustomSwitch) {
    return (
        <SwitchRow>
            <label htmlFor={id}>
                {children}
            </label>

            <SwitchBody name={id} id={id} defaultChecked={defaultActive === true} >
                <SwitchThumb />
            </SwitchBody>
        </SwitchRow>
    )
}