import React from 'react';
import { FaTimes } from "react-icons/fa";
import { Box, BoxTitle } from "../../styles/global";
import { Button } from "../Button";
import { BoxContent, PurchaseBagFooter } from "./style";

export function ShoppingCart() {
    return (
        <Box>
            <BoxTitle variant="colorful">
                Seu carrinho
            </BoxTitle>

            <BoxContent>
                <p>
                    Você pode tornar-se um dos membros dessa alta sociedade, basta clicar no link abaixo para saber mais sobre todas as vantagens e conhecer os preços.
                </p>

                <ul>
                    <li>
                        Pacote Branco
                        <button><FaTimes /></button>
                    </li>
                    <li>
                        Pacote Branco 2
                        <button><FaTimes /></button>
                    </li>
                    <li>
                        Vip Beta
                        <button><FaTimes /></button>
                    </li>
                    <li>
                        Vip Teste
                        <button><FaTimes /></button>
                    </li>
                </ul>

                <PurchaseBagFooter>
                    <strong>R$ 300,00</strong>

                    <Button variant="success" hasIcon={true} icon="shopping-basket" size="small">
                        Comprar
                    </Button>
                </PurchaseBagFooter>
            </BoxContent>
        </Box>
    )
}