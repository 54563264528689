import React from 'react';
import { RadioGroup, RadioItem } from "./style";

export function GenderRadioGroup() {
    return (
        <RadioGroup>
            <RadioItem value="male"><img src="/male.png" alt="" /></RadioItem>
            <RadioItem value="female"><img src="/female.png" alt="" /></RadioItem>
        </RadioGroup>
    )
}