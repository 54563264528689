import React from 'react';
import { BadgesRow, Box, BoxContent, BoxContentLink, BoxTitle } from "../../styles/global";
import { StaffCard } from "./Components/StaffCard";
import { AsideContainer, StaffPageLayout, MainContainer } from "./style";


export function StaffPage() {
    return (
        <StaffPageLayout>
            <MainContainer>

            <StaffCard role="Ceo" key={"Ceo"} />
            <StaffCard role="Diretor" key={"Diretor"} />
            <StaffCard role="Administrador" key={"Administrador"} />
            <StaffCard role="Moderação" key={"Moderação"} />

            </MainContainer>

            <AsideContainer>
                <Box>
                    <BoxTitle variant="colorful">
                        O que é a staff
                    </BoxTitle>
                    <BoxContent>
                        <span>
                            A Equipe Habblive está sempre à sua disposição em prol de ajudar o máximo de usuários possíveis em meio a quaisquer tipo de desafio acontecido dentro da comunidade.
                        </span>
                        <BadgesRow>
                            <img src="/emblema-staff.gif" alt="" />
                        </BadgesRow>
                    </BoxContent>
                </Box>


                <Box>
                    <BoxTitle variant="colorful">
                        Trabalhe conosco
                    </BoxTitle>
                    <BoxContent>
                        <span>
                            O Habblive Hotel sempre oferta vagas para que os usuários mais presents juntem-se a nós na missão de entregar entretenimento e segurança aos hóspedes. Confira os projetos existentes e veja como juntar-se a eles.
                        </span>

                        <BoxContentLink to="/noticiaaqui">
                            Redação
                        </BoxContentLink>

                        <BoxContentLink to="/noticiaqui2">
                            Moderação
                        </BoxContentLink>
                    </BoxContent>
                </Box>
            </AsideContainer>
        </StaffPageLayout>
    )
}