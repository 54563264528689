import React from "react";
import { NavLink } from "react-router-dom";
import { ItemContent, ItemHeader } from "../subcomponents/AccordionElements";
import { AccordionContainer, AccordionHeader, AccordionItem } from "./styleAccordion";

import { subNavPathes } from "../loggedHeader";
import { Close } from "./styleDialog";

export function LoggedMobileNavigation() {
    return (
        <AccordionContainer type="single" collapsible >

            <AccordionItem value="home">
                <ItemHeader>
                    Usuário
                </ItemHeader>
                <ItemContent>
                    { subNavPathes["/"].map(nav => (
                        nav.external !== true ?
                            <Close key={nav.label} asChild={true}><NavLink to={nav.path}>{nav.label}</NavLink></Close>
                            :
                            <a key={nav.label} href={nav.path}>{nav.label}</a>
                    )) }
                </ItemContent>
            </AccordionItem>

            <AccordionItem value="community">
                <ItemHeader>
                    Comunidade
                </ItemHeader>
                <ItemContent>
                    { subNavPathes["/comunidade"].map(nav => (
                        nav.external !== true ?
                            <Close key={nav.label} asChild={true}><NavLink to={nav.path}>{nav.label}</NavLink></Close>
                            :
                            <a key={nav.label} href={nav.path}>{nav.label}</a>
                    )) }
                </ItemContent>
            </AccordionItem>

            <AccordionItem value="loja">
                <ItemHeader>
                    Loja
                </ItemHeader>
                <ItemContent>
                    { subNavPathes["/loja"].map(nav => (
                        nav.external !== true ?
                            <Close key={nav.label} asChild={true}><NavLink to={nav.path}>{nav.label}</NavLink></Close>
                            :
                            <a key={nav.label} href={nav.path}>{nav.label}</a>
                    )) }
                </ItemContent>
            </AccordionItem>

            <AccordionItem value="halldafama">
                <ItemHeader>
                    Hall da Fama
                </ItemHeader>
                <ItemContent>
                    { subNavPathes["/halldafama"].map(nav => (
                        nav.external !== true ?
                            <Close key={nav.label} asChild={true}><NavLink to={nav.path}>{nav.label}</NavLink></Close>
                            :
                            <a key={nav.label} href={nav.path}>{nav.label}</a>
                    )) }
                </ItemContent>
            </AccordionItem>

        </AccordionContainer>
    )
}


// Deslogado

export function UnloggedMobileNavigation() {
    return (
        <AccordionContainer type="single" collapsible >

            <AccordionItem value="home">
                <ItemHeader>
                    Usuário
                </ItemHeader>
                <ItemContent>
                    <Close asChild={true}><NavLink to="/">Home</NavLink></Close>
                </ItemContent>
            </AccordionItem>

        </AccordionContainer>
    )
}