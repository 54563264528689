import React, { ReactNode } from "react";
import { Button } from "../../../../components/Button";
import { ProductContainer, ProductHeader, ProductInfosContainer } from "./style";

type ProductCardProps = {
    image: string;
    packName: string;
    price: string;
    children: ReactNode;
    titleColor: "blue" | "orange" | "red" | "white";
}

export function ProductCard(props: ProductCardProps) {
    return (
        <ProductContainer>
            <img src={props.image} alt="" />

            <ProductInfosContainer>
                <ProductHeader color={props.titleColor}>
                    <h3>{props.packName}</h3>
                    <span>{props.price}</span>
                </ProductHeader>

                {props.children}

                <Button hasIcon={true} icon="plus" variant="success" size="small">
                    Adicionar ao carrinho
                </Button>
            </ProductInfosContainer>
        </ProductContainer>
    )
}