import { Description } from "@radix-ui/react-dialog";
import React, { createContext, ReactNode } from "react";


// 🧩 Types of partials

type badgeInformationsType = {
    image: string;
    title: string;
    code: string;
    description: string;
}

type roomInformationsType = {
    name: string;
    id: number;
    cover: string;
}

type picturesType = {
    image: string;
    date: string;
    likes: number;
}

type coinsType = {
    coin: "cambios" | "diamantes" | "gamepoints" | "promocoins" | "badges" | "respects"
    amount: number;
}

// 🖼️ Context types

type UserInformations = {
    isLogged: boolean;
    isAdmin: boolean;
    lastLoggin: string;
    createdAt: string;
    nickname: string;
    mission: string;
    id: number;
    onlineFriends: string[];
    friendsRequest: number;
    badges: badgeInformationsType[];
    allFriends: string[];
    rooms: roomInformationsType[];
    pictures: picturesType[];
    coins: coinsType[]
}

type UserContextProps = {
    userInfos: UserInformations
}

type ProviderProps = {
    children: ReactNode;
}

export const UserContext = createContext({} as UserContextProps)



// 📦 PROVIDEN INFORMATIONS

export function UserContextProvider({children}: ProviderProps) {
    const userInfos: UserInformations = {
        isLogged: true,
        isAdmin: true,
        lastLoggin: "2022-11-19T21:02:08.551Z",
        createdAt: "2018-12-31T11:02:02.431Z",
        nickname: "Floricultor",
        mission: "Web Diva Tulla Floriana 59 abrubrubrbrbrbrbrbrdr osj fue",
        id: 3232984,
        onlineFriends: [
          "Kyzor",
          "vini259.",
          "Blume",
          "Hunter",
          "Victor.M",
          "Nayara",
          "Parmesan"
        ],
        friendsRequest: 12,
        badges: [
            {
                image: "/sample-badge.gif",
                title: "Ervas botânicas",
                description: "Plantas raras e exóticas com propriedades medicinais.",
                code: "KAIO001",
            },
            {
                image: "/sample-badge.gif",
                title: "Ervas botânicas",
                description: "Plantas raras e exóticas com propriedades medicinais.",
                code: "KAIO001",
            },
            {
                image: "/sample-badge.gif",
                title: "Ervas botânicas",
                description: "Plantas raras e exóticas com propriedades medicinais.",
                code: "KAIO001",
            },
            {
                image: "/sample-badge.gif",
                title: "Ervas botânicas",
                description: "Plantas raras e exóticas com propriedades medicinais.",
                code: "KAIO001",
            },
            {
                image: "/sample-badge.gif",
                title: "Ervas botânicas",
                description: "Plantas raras e exóticas com propriedades medicinais.",
                code: "KAIO001",
            },
            {
                image: "/sample-badge.gif",
                title: "Ervas botânicas",
                description: "Plantas raras e exóticas com propriedades medicinais.",
                code: "KAIO001",
            },
            {
                image: "/sample-badge.gif",
                title: "Ervas botânicas",
                description: "Plantas raras e exóticas com propriedades medicinais.",
                code: "KAIO001",
            },
            {
                image: "/sample-badge.gif",
                title: "Ervas botânicas",
                description: "Plantas raras e exóticas com propriedades medicinais.",
                code: "KAIO001",
            },
            {
                image: "/sample-badge.gif",
                title: "Ervas botânicas",
                description: "Plantas raras e exóticas com propriedades medicinais.",
                code: "KAIO001",
            },
            {
                image: "/sample-badge.gif",
                title: "Ervas botânicas",
                description: "Plantas raras e exóticas com propriedades medicinais.",
                code: "KAIO001",
            },
            {
                image: "/sample-badge.gif",
                title: "Ervas botânicas",
                description: "Plantas raras e exóticas com propriedades medicinais.",
                code: "KAIO001",
            },
        ],
        allFriends: [
            "Kyzor",
            "vini259.",
            "Blume",
            "Hunter",
            "Victor.M",
            "Nayara",
            "Parmesan",
            "Gabriella",
            "Danna",
            "Larissa",
            "Pretty",
            "Mayla",
            "Krauser",
            "Danyel",
        ],
        rooms: [
            {
                cover: "/room-base.png",
                name: "hahhahahahahha",
                id: 391392,
            },
            {
                cover: "/room-base.png",
                name: "hahhahahahahha",
                id: 392833,
            },
            {
                cover: "/room-base.png",
                name: "hahhahahahahha",
                id: 943945,
            },
            {
                cover: "/room-base.png",
                name: "hahhahahahahha",
                id: 394750,
            },
            {
                cover: "/room-base.png",
                name: "hahhahahahahha",
                id: 192384,
            },
        ],
        pictures: [
            {
                image: "https://atlantaphotography.site/public/purchased/habblive/f1e2314aaac3bd49f0848f0436f2a21c.png",
                date: "2022-11-19T20:13:36Z",
                likes: 12
            },
            {
                image: "https://atlantaphotography.site/public/purchased/habblive/f1e2314aaac3bd49f0848f0436f2a21c.png",
                date: "2022-11-19T20:18:06.258Z",
                likes: 12
            },
            {
                image: "https://atlantaphotography.site/public/purchased/habblive/f1e2314aaac3bd49f0848f0436f2a21c.png",
                date: "2022-11-19T20:18:22.567Z",
                likes: 12
            },
            {
                image: "https://atlantaphotography.site/public/purchased/habblive/f1e2314aaac3bd49f0848f0436f2a21c.png",
                date: "2022-11-19T20:18:47.384Z",
                likes: 12
            },
            {
                image: "https://atlantaphotography.site/public/purchased/habblive/f1e2314aaac3bd49f0848f0436f2a21c.png",
                date: "2022-11-19T20:19:02.975Z",
                likes: 12
            },
        ],
        coins: [
            {
                coin: "cambios",
                amount: 100000,
            },
            {
                coin: "diamantes",
                amount: 1209301,
            },
            {
                coin: "gamepoints",
                amount: 12,
            },
            {
                coin: "promocoins",
                amount: 8,
            },
            {
                coin: "badges",
                amount: 293,
            },
            {
                coin: "respects",
                amount: 1283930,
            },
        ]
    }

    return (
       <UserContext.Provider value={{userInfos}}>
            {children}
       </UserContext.Provider>
    )
}