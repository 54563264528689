import * as Dialog from "@radix-ui/react-dialog"
import styled, { keyframes } from "styled-components"


export const open = keyframes`
    from {
        transform: scale(0.8);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
`

export const close = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
`


export const Close = styled(Dialog.Close)``

export const Description = styled(Dialog.Description)`
    display: none;
`

export const Title = styled(Dialog.Title)``

export const Content = styled(Dialog.Content)`
    position: fixed;
    top: 50%;
    left: 50%;
    /* transform: translateY(-50%) translateX(-50%); */
    animation-fill-mode: both;

    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
    padding: 20px;

    background: rgba(255, 255, 255, 0.8);
    border: 1px solid ${p => p.theme["gray-400"]};
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    gap: 16px;

    top: 10px;
    left: 10px;

    &[data-state="open"] {
        animation: ${open} 200ms cubic-bezier(0, 1.32, 0.16, 1.11);
        transform: scale(1);
        opacity: 1;
        transition: inset .1s .3s;
    }

    &[data-state="closed"] {
        animation: ${close} 100ms ease-out;
        transform: scale(0.8);
        transition: all .1s .2s;
    }

    &:focus {
        outline: none;
    }
`

export const Overlay = styled(Dialog.Overlay)`
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.5);  
    }
`

export const Portal = styled(Dialog.Portal)`
`

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

export const Trigger = styled(Dialog.Trigger)``

export const Container = styled(Dialog.Root)`
`