import * as RadioPrimitive from "@radix-ui/react-radio-group"
import styled from "styled-components"


export const RadioItem = styled(RadioPrimitive.Item)`
    width: 67px;
    height: 67px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 5px;
    background: ${p => p.theme["gray-300"]};
    box-shadow: 0px 2px 0px 0px #00000005;
    user-select: none;

    outline: 1px solid ${p => p.theme["gray-400"]};
    outline-offset: -1px;
    image-rendering: pixelated;

    &[value="male"][data-state="checked"] {
        background: ${p => p.theme["blue-500"]};
    }

    &[value="female"][data-state="checked"] {
        background: ${p => p.theme["pink-500"]};
    }
`

export const RadioGroup = styled(RadioPrimitive.Root)`
    display: flex;
    flex-direction: row;
    gap: 6px;
`