import styled, { css } from "styled-components";

type ButtonStyle = {
    hasIcon: boolean;
    size: "medium" | "small";
    variant: "primary" | "secondary" | "success" | "danger";
}

export const ButtonLayout = styled.button<ButtonStyle>`
    &:focus {
        box-shadow: none;
        transition: box-shadow 50ms;
        outline-width: 3px;
    }


    ${p => p.variant === "primary" && css`
        background: ${p => p.theme["blue-500"]};
        
        &:focus {
            outline-color: rgba(0, 167, 239, 0.25);
        }
    `}

    ${p => p.variant === "secondary" && css`
        background: ${p => p.theme["gray-1000"]};

        &:hover {
            filter: none;
            background: ${p => p.theme["gray-900"]};
        }

        &:active {
            filter: none;
            background: ${p => p.theme["black"]};
        }
    `}

    ${p => p.variant === "success" && css`
        background: ${p => p.theme["success-500"]};
        
        &:focus {
            outline-color: rgba(0, 204, 57, 0.25);
        }
    `}

    ${p => p.variant === "danger" && css`background: ${p => p.theme["danger-500"]};`}

    ${props => props.hasIcon === false &&  css`
        svg { display: none; }
    `}

    ${props => props.size === "small" && css`
        font-size: ${p => p.theme["text-sm"]};
        font-weight: bold;

        svg {
            width: 16px;
            height: 16px;
        }
    `}
`