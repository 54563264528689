import React from 'react';
import { Article } from "../../components/Articles";
import { Button } from "../../components/Button";
import { FullTitle } from "../../components/FullTitle";
import { Tooltip } from "../../components/Tooltip";
import { Box, BoxTitle, Input } from "../../styles/global";
import { DarkContainer, Form, LastArticlesRow, MainContainer, MainContent, RegisteredUserItem, RegisteredUsers, Wrapper } from "./styles";
import { ArticleProps } from "../../components/Articles"
import { DefaultDialog } from "./RegisterDialog";
import { useContext } from "react";
import { ApisContext } from "../../Contexts/ApisContext";

// fake api

const fakeUsers = ["Floricultor", "Mayla", "Kyzor", "vini259.", "Blume", "VictorM", "Habblive", "Sandra", "Alice", "Krauser", "Jocael", "Parmesan"]

export function Index() {
    const { LastArticles } = useContext(ApisContext)

    return (
        <MainContainer>
            <MainContent>
            <div>
                    <h2>Entre no Habblive</h2>
                    <DarkContainer>
                        <Form>
                            <label>
                                Nickname
                                <Input type="text" placeholder="Joaozinho" />
                            </label>
                            <label>
                                Senha
                                <Input type="password" placeholder="***" />
                            </label>

                            <div>
                                <a href="">Esqueci minha senha</a>
                                <Button type="button" variant="success">Entrar</Button>
                            </div>
                        </Form>

                        <Wrapper>
                            <Box>
                                <BoxTitle variant="clean">Últimos registrados</BoxTitle>

                                <RegisteredUsers>
                                    {fakeUsers.map(user => {
                                        return (
                                            <Tooltip content={user} key={user}>
                                                <RegisteredUserItem>
                                                    <img src={`https://habblive.in/imageeer.php?user=${user}&action=wav&size=b&head_direction=3&direction=3&gesture=sml&headonly=1`} alt="" />
                                                </RegisteredUserItem>
                                            </Tooltip>
                                        )
                                    })}
                                </RegisteredUsers>
                                <DefaultDialog>registre-se</DefaultDialog>
                            </Box>
                        </Wrapper>
                    </DarkContainer>
                </div>

                <FullTitle>Notícias recentes</FullTitle>

                <LastArticlesRow>
                    {LastArticles.map((article) => {
                        return (
                            <Article
                            title={article.title}
                            description={article.description}
                            topstoryUrl={article.coverUrl}
                            id={article.id}
                            key={article.id}
                        />
                        )
                    })}
                </LastArticlesRow>
            </MainContent>
        </MainContainer>
    )
}